import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Const from '../utils/defaultConstant'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import $ from 'jquery';
import AlertGeneral from '../atoms/AlertGeneral'
import {alertaGeneral,closeAlertGeneral, endSession} from '../../assets/js/GeneralScript'
import InputTypeMarketplace from '../atoms/InputTypeMarketplace'
import InputTypeDocument from '../atoms/InputTypeDocument'
import InputStatusMarket from '../atoms/InputStatusMarket'
import InputSectors from '../atoms/InputSectors'
import langEs from '../utils/langEs'
document.body.classList.add('fix-sidebar');

class Marketplace extends Component {

    componentDidMount() {
                   var el = document.getElementById('mggAlert');
                   if (el) {
                       el.addEventListener("click", closeAlertGeneral);
                   }
        function validURL(str) {
            var pattern = new RegExp('^(https?:\\/\\/)?' +
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                '((\\d{1,3}\\.){3}\\d{1,3}))' +
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
                '(\\?[;&a-z\\d%_.~+=-]*)?' +
                '(\\#[-a-z\\d_]*)?$', 'i');
            return !!pattern.test(str);
        }
        let itemfaqs = ' <div class="itemfaqs">' +
                        '<button class="addCatFaqsmenosppal">-</button>' +
                        '<input type="text" class="form-control tfaqs" placeholder="Nombre categoría"   id="tpreguntas"  max="120" /> ' +
                        '<a href="#nogo" class="addCatFaqsmas">Agregar Pregunta y respuesta</a><div class="separador"></div>' +
                        '<div class="groupInputFaqs">' +
                        '<button class="addCatFaqsmenos">-</button>' +
                        '<input type="text" class="form-control qfaqs" placeholder="Pregunta"   id="pregunta"  max="120" /> ' +
                        '<input type="text" class="form-control rfaqs" placeholder="Respuesta"   id="respuesta"  max="255" />' +
                        '</div>' +
                        '</div> ';

        let itemquestion =  '<div class="groupInputFaqs">' +
                            '<button class="addCatFaqsmenos">-</button>' +
                            '<input type="text" class="form-control qfaqs" placeholder="Pregunta"   id="pregunta"  max="120" /> ' +
                            '<input type="text" class="form-control rfaqs" placeholder="Respuesta"   id="respuesta"  max="255" />' +
                            '</div>';
                            
        document.querySelector('.addCatFaqs').addEventListener("click", function (e) {            
            document.querySelector('.form-group-faqs').insertAdjacentHTML("afterend", itemfaqs)
            return false
        });

        document.querySelector('.addCatFaqsmenosppal').addEventListener("click", function (e) {
            e.parentElement.querySelector('.itemfaqs').remove();
             return false
        });

        $(document).on('click', '.addCatFaqsmas', function () {
            $(this).parent(".itemfaqs").append(itemquestion)
            return false
        });
        $(document).on('click', '.addCatFaqsmenos', function () {
            $(this).parent(".groupInputFaqs").remove()
            return false
        });

        $(document).on('click', '.btnupdate', function () {
        
            let social = []
            $(".urlsocial").each(function (index) {
                if (this.value != "") {
                    let url = validURL(this.value)
                    if (url) {
                        social.push({
                            "name": this.id,
                            "url": this.value
                        })
                    } 
                }
            });
            let faqs = []
            $(".itemfaqs").each(function (index) {
                if ($(this).find(".tfaqs").value != "") {
                    let Questions = []
                    let titlefaqs = $(this).find(".tfaqs").val()
                    let groupQuestions = $(this).find(".groupInputFaqs")
                    $(groupQuestions).each(function (index) {
                        if ($(this).find(".qfaqs").val() != "" &&
                            $(this).find(".rfaqs").val() != "") {
                            Questions.push({
                                "pregunta": $(this).find(".qfaqs").val(),
                                "respuesta": $(this).find(".rfaqs").val()
                            })
                        }                        
                    })
                    if (Questions.length >= 1) {
                        faqs.push({
                            "categoria": titlefaqs,
                            "respuestas": Questions
                        })
                    }
                }
            });
            let name = $("#updname").val();
            let linkAppStore = $("#updlinkAppStore").val()
            let numberDocument = $("#updnumberDocument").val()
            let phone = $("#updphone").val()
            let note = $("#updnote").val()
            let address = $("#updaddress").val()
            let versMarketplace = $("#updversMarketplace").val()
            let textTyc = $("#updtextTyc").val()
            let textPd = $("#updtextPd").val()
            let textAutoriza = $("#updtextAutoriza").val()
            let privKeyFireBase = $("#updprivKeyFireBase").val()

            if (($('#updImagenes_ImagenSuperior').get(0).files.length != 0) &&
                ($('#updImagenes_LogoTienda').get(0).files.length != 0) &&
                ($('#updImagenes_UsuarioMarket').get(0).files.length != 0) &&
                ($('#updImagenes_User').get(0).files.length != 0) &&
                ($('#updImagenes_Ojo').get(0).files.length != 0) &&
                ($('#updImagenes_Mayorque').get(0).files.length != 0) &&
                ($('#updImagenes_InstagramMarket').get(0).files.length != 0) &&
                ($('#updImagenes_FacebookMarket').get(0).files.length != 0) &&
                ($('#updImagenes_LinkedingMarket').get(0).files.length != 0) &&
                ($('#updImagenes_TwitterMarket').get(0).files.length != 0) &&
                ($('#updImagenes_WhatsappMarket').get(0).files.length != 0) &&
                ($('#updImagenes_YoutubeMarket').get(0).files.length != 0) &&
                ($('#updImagenes_BlackArrowMarket').get(0).files.length != 0) &&
                ($('#updImagenes_Hamburguesa').get(0).files.length != 0) &&
                ($('#updImagenes_CloseBlackMarket').get(0).files.length != 0) &&                                                                                
                name.length >= 4 &&
                numberDocument.length >= 6 &&
                phone.length >= 7 &&
                note.length >= 5 &&
                address.length >= 4 &&
                versMarketplace.length >= 2 &&
                textTyc.length >= 40 &&
                textPd.length >= 40  &&
                textAutoriza.length >= 40 &&
                privKeyFireBase.length >= 40 &&
                linkAppStore != "") {
                const datos = new FormData(document.getElementById('formulario'));
                datos.append('socialRef',JSON.stringify(social))
                datos.append('faqs',JSON.stringify(faqs))
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "/api/marketplace", { // ok
                    headers: { 
                        /* 'Accept': 'application/json',
                        'Content-Type': 'application/json', */
                        'Authorization': token,
                    },
                    method: "POST",
                    body: datos 
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            if (result.data){
                                alertaGeneral("Registro Ingresado");
                                window.scrollTo(0, 0);
                                document.getElementById("imgshowImagenSuperior").src = ""
                                document.getElementById("imgshowLogoTienda").src = ""
                                document.getElementById("imgshowUsuarioMarket").src = ""
                                document.getElementById("imgshowUser").src = ""
                                document.getElementById("imgshowOjo").src = ""
                                document.getElementById("imgshowMayorque").src = ""
                                document.getElementById("imgshowInstagramMarket").src = ""
                                document.getElementById("imgshowFacebookMarket").src = ""
                                document.getElementById("imgshowLinkedingMarket").src = ""
                                document.getElementById("imgshowTwitterMarket").src = ""
                                document.getElementById("imgshowWhatsappMarket").src = ""
                                document.getElementById("imgshowYoutubeMarket").src = ""
                                document.getElementById("imgshowBlackArrowMarket").src = ""
                                document.getElementById("imgshowHamburguesa").src = ""
                                document.getElementById("imgshowCloseBlackMarket").src = ""
                                document.getElementById("facebook").value = ""
                                document.getElementById("youtube").value = ""
                                document.getElementById("instagram").value = ""
                                document.getElementById("twitter").value = ""
                                document.getElementById("linkedin").value = ""
                                document.getElementById("whatsapp").value = ""
    
                                $(".itemfaqs").each(function (index) {
                                    document.querySelector(".itemfaqs").remove()
                                })
                                itemfaqs = ' <div class="itemfaqs">' +
                                    '<button class="addCatFaqsmenosppal">-</button>' +
                                    '<input type="text" class="form-control tfaqs" placeholder="Nombre categoría"   id="tpreguntas"  max="120" /> ' +
                                    '<a href="#nogo" class="addCatFaqsmas">Agregar Pregunta y respuesta</a><div class="separador"></div>' +
                                    '<div class="groupInputFaqs">' +
                                    '<button class="addCatFaqsmenos">-</button>' +
                                    '<input type="text" class="form-control qfaqs" placeholder="Pregunta"   id="pregunta"  max="120" /> ' +
                                    '<input type="text" class="form-control rfaqs" placeholder="Respuesta"   id="respuesta"  max="255" />' +
                                    '</div>' +
                                    '</div> ';
                                document.querySelector('.form-group-faqs').insertAdjacentHTML("afterend", itemfaqs)
                                document.getElementById("formulario").reset()
                            }else{
                                alertaGeneral("Revisa la conexión a internet")
                            }    
                        }
                    }
                )
            } else 
                alertaGeneral("Revisa los campos, todos son obligatorios")
        })
    }
    render(){

        function validOnChange(filex,imgshow,ancho,alto,imgInput) {
            if (filex[0] != undefined) {
                let output = document.getElementById(imgshow);
                output.src = URL.createObjectURL(filex[0]);
                output.onload = function() {
                    if ((output.naturalWidth.toFixed(0) > ancho) || (output.naturalHeight.toFixed(0) > alto)) {
                        alertaGeneral("La Imagen seleccionada no cumple con la medida mínima proporcional de  ancho:"+ancho+" * alto:"+alto+" pixels")
                        document.getElementById(imgshow).src = ''
                        $('#'+imgInput).value = ""
                        sessionStorage.setItem("registerValid","false")
                    } else
                        sessionStorage.setItem("registerValid","true")
                    URL.revokeObjectURL(output.src) // free memory
                }
            }
        }

        return (
            <div>
            <Headerdashboard/>
            <Sidebar />
            <AlertGeneral /> 
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-12 align-self-center">
                            <h3 className="text-primary">MarketPlace</h3> 
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-title">
                                        <h4>Crear </h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="horizontal-form">
                                            <div className="form-horizontal" id="formcreate" >
                                            <form id="formulario" >
                                                <div className="form-group">
                                                    <div className="row p-t-20">
                                                        <InputStatusMarket />
                                                        <InputTypeMarketplace />
                                                        <div className="col-md-5">
                                                            <div className="form-group">
                                                                <label className=" control-label">{langEs.NAME_MARKETPLACE}</label>
                                                                <input type="text" className="form-control" placeholder="" name="name" id="updname"   max="45" />
                                                            </div> 
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className="control-label">{langEs.VERSION} Marketplace </label>                                                        
                                                                <input type="text" className="form-control" placeholder="" name="versMarketplace" id="updversMarketplace"   step="0.01" max="10" />
                                                            </div>                                                            
                                                        </div>
                                                    </div>  
                                                    <div className="row p-t-20">
                                                        <div className="col-md-9">
                                                            <div className="form-group">
                                                                <label className=" control-label">Url App Play Store</label>
                                                                <input type="text" className="form-control" placeholder="" name="linkAppStore" id="updlinkAppStore"   max="45" />
                                                            </div> 
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className="control-label">Tipo de Negocio </label>                                                        
                                                                <select name="typeBusiness" className="form-control" id="updtypeBusiness">
                                                                    <option value="1">B2B</option>
                                                                    <option value="2">B2C</option>
                                                                </select>
                                                            </div>                                                            
                                                        </div>
                                                        <InputSectors />
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className="control-label">Vitrina Pública</label>                                                        
                                                                <select name="vitrinaMarkeplace" className="form-control" id="updvitrinaMarketplace">
                                                                    <option value="1">Activa</option>
                                                                    <option value="2">Inactiva</option>
                                                                </select>
                                                            </div>                                                            
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className=" control-label">{langEs.NAME_CLIENT} </label>
                                                                <input type="text" className="form-control" placeholder="" name="nameClient" id="updnameClient"   max="30"  />
                                                             </div>
                                                        </div>

                                                    </div>
                                                    <div className="row p-t-20">
                                                        <InputTypeDocument />
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className=" control-label">{langEs.DOCUMENT_NUMBER} </label>
                                                                <input type="number" className="form-control" placeholder="" name="numberDocument" id="updnumberDocument"   max="10" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label className="control-label">{langEs.PHONE} </label>
                                                                <input type="number" className="form-control" placeholder="" name="phone" id="updphone"   max="12"   />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <div className="form-group">
                                                                <label className=" control-label">{langEs.ADDRESS} </label>
                                                                <input type="text" className="form-control" placeholder="" name="address" id="updaddress"   max="90" />
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    <div className="row p-t-20">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label className=" control-label">{langEs.TEXT_PD}  </label>
                                                                <textarea name="textPd" id="updtextPd"></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">                                                                    
                                                                <label className=" control-label">{langEs.TEXT_NOTE}</label>
                                                                <textarea name="note" id="updnote"></textarea>                                                                 
                                                            </div>
                                                        </div>
                                                    </div>    
                                                    <div className="row p-t-20">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label className=" control-label">{langEs.TEXT_TC}</label>
                                                                <textarea name="textTyc" id="updtextTyc"></textarea>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    <div className="row p-t-20">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label className=" control-label">Autorización de Tratamiento de Datos</label>
                                                                <textarea name="textAutoriza" id="updtextAutoriza"></textarea>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    <div className="row p-t-20">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label className=" control-label">Clave Privada FireBase</label>
                                                                <textarea name="privKeyFireBase" id="updprivKeyFireBase"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>   
                                                    <div class="contBlock">
                                                        <div className="row p-t-20">
                                                            <label class=" col-md-12 control-label bold">Características de las fotos publicitarias para las App's</label>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Peso en kilobites </label>                                                                
                                                                    <input type="number" className="form-control" placeholder="" name="weight" id="updweight" required="required"  max="10" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Ancho en pixeles</label>                                                                
                                                                    <input type="number" className="form-control" placeholder="" name="width" id="updwidth" required="required"  max="10"  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Alto  en pixeles </label>                                                                
                                                                    <input type="number" className="form-control" placeholder="" name="height" id="updheight" required="required" max="10" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>   
                                                <div className="contBlock">
                                                    <div className="row p-t-20">
                                                        <div className="col-md-12">
                                                            <label className=" control-label bold">Imagenes Adjuntas al Marketplace </label>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <img src="" alt="" id="imgshowImagenSuperior" height="50" />   
                                                                        <label style ={{marginLeft:10}} className=" control-label">ImagenSuperior</label>                                                        
                                                                        <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_ImagenSuperior" 
                                                                               id="updImagenes_ImagenSuperior" required="required" max="7" 
                                                                               style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                               onChange ={(e) => validOnChange(e.target.files,"imgshowImagenSuperior",400,72,'updImagenes_ImagenSuperior')}  />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <img src="" alt="" id="imgshowLogoTienda" height="50" />   
                                                                        <label style ={{marginLeft:10}} className=" control-label">LogoTienda</label>                                                       
                                                                        <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_LogoTienda" 
                                                                               id="updImagenes_LogoTienda" required="required" max="7" 
                                                                               style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                               onChange ={(e) => validOnChange(e.target.files,"imgshowLogoTienda",375,145,'updImagenes_LogoTienda')}  />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <img src="" alt="" id="imgshowUsuarioMarket" height="50" />   
                                                                        <label style ={{marginLeft:10}} className=" control-label">UsuarioMarket</label>                                                       
                                                                        <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_UsuarioMarket" 
                                                                               id="updImagenes_UsuarioMarket" required="required" max="7" 
                                                                               style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                               onChange ={(e) => validOnChange(e.target.files,"imgshowUsuarioMarket",85,85,'updImagenes_UsuarioMarket')}  />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <img src="" alt="" id="imgshowUser" height="50" />   
                                                                        <label style ={{marginLeft:10}} className=" control-label">User Contraseña</label>                                                       
                                                                        <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_User" 
                                                                               id="updImagenes_User" required="required" max="7" 
                                                                               style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                               onChange ={(e) => validOnChange(e.target.files,"imgshowUser",15,22,'updImagenes_User')}  />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <img src="" alt="" id="imgshowOjo" height="50" />   
                                                                        <label style ={{marginLeft:10}} className=" control-label">Ojo Contraseña</label>                                                       
                                                                        <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Ojo" 
                                                                               id="updImagenes_Ojo" required="required" max="7" 
                                                                               style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                               onChange ={(e) => validOnChange(e.target.files,"imgshowOjo",23,23,'updImagenes_Ojo')}  />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <img src="" alt="" id="imgshowMayorque" height="50" />   
                                                                        <label style ={{marginLeft:10}} className=" control-label">Preguntas Mayorque </label>                                                       
                                                                        <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Mayorque" 
                                                                               id="updImagenes_Mayorque" required="required" max="7" 
                                                                               style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                               onChange ={(e) => validOnChange(e.target.files,"imgshowMayorque",23,23,'updImagenes_Mayorque')}  />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <img src="" alt="" id="imgshowInstagramMarket" height="50" />   
                                                                        <label style ={{marginLeft:10}} className=" control-label">InstagramMarket </label>                                                       
                                                                        <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_InstagramMarket" 
                                                                               id="updImagenes_InstagramMarket" required="required" max="7" 
                                                                               style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                               onChange ={(e) => validOnChange(e.target.files,"imgshowInstagramMarket",75,75,'updImagenes_InstagramMarket')}  />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <img src="" alt="" id="imgshowFacebookMarket" height="50" />   
                                                                        <label style ={{marginLeft:10}} className=" control-label">FacebookMarket </label>                                                       
                                                                        <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_FacebookMarket" 
                                                                               id="updImagenes_FacebookMarket" required="required" max="7" 
                                                                               style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                               onChange ={(e) => validOnChange(e.target.files,"imgshowFacebookMarket",75,75,'updImagenes_FacebookMarket')}  />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <img src="" alt="" id="imgshowLinkedingMarket" height="50" />   
                                                                        <label style ={{marginLeft:10}} className=" control-label">LinkedingMarket </label>                                                       
                                                                        <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_LinkedingMarket" 
                                                                               id="updImagenes_LinkedingMarket" required="required" max="7" 
                                                                               style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                               onChange ={(e) => validOnChange(e.target.files,"imgshowLinkedingMarket",75,75,'updImagenes_LinkedingMarket')}  />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <img src="" alt="" id="imgshowTwitterMarket" height="50" />   
                                                                        <label style ={{marginLeft:10}} className=" control-label">TwitterMarket </label>                                                       
                                                                        <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_TwitterMarket" 
                                                                               id="updImagenes_TwitterMarket" required="required" max="7" 
                                                                               style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                               onChange ={(e) => validOnChange(e.target.files,"imgshowTwitterMarket",75,75,'updImagenes_TwitterMarket')}  />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <img src="" alt="" id="imgshowWhatsappMarket" height="50" />   
                                                                        <label style ={{marginLeft:10}} className=" control-label">WhatsappMarket </label>                                                       
                                                                        <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_WhatsappMarket" 
                                                                               id="updImagenes_WhatsappMarket" required="required" max="7" 
                                                                               style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                               onChange ={(e) => validOnChange(e.target.files,"imgshowWhatsappMarket",75,75,'updImagenes_WhatsappMarket')}  />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <img src="" alt="" id="imgshowYoutubeMarket" height="50" />   
                                                                        <label style ={{marginLeft:10}} className=" control-label">YoutubeMarket </label>                                                       
                                                                        <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_YoutubeMarket" 
                                                                               id="updImagenes_YoutubeMarket" required="required" max="7" 
                                                                               style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                               onChange ={(e) => validOnChange(e.target.files,"imgshowYoutubeMarket",75,75,'updImagenes_YoutubeMarket')}  />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <img src="" alt="" id="imgshowBlackArrowMarket" height="50" />   
                                                                        <label style ={{marginLeft:10}} className=" control-label">BackArrow </label>                                                       
                                                                        <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_BlackArrowMarket" 
                                                                               id="updImagenes_BlackArrowMarket" required="required" max="7" 
                                                                               style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                               onChange ={(e) => validOnChange(e.target.files,"imgshowBlackArrowMarket",75,75,'updImagenes_BlackArrowMarket')}  />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <img src="" alt="" id="imgshowHamburguesa" height="50" />   
                                                                        <label style ={{marginLeft:10}} className=" control-label">Hamburguesa </label>                                                       
                                                                        <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Hamburguesa" 
                                                                               id="updImagenes_Hamburguesa" required="required" max="7" 
                                                                               style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                               onChange ={(e) => validOnChange(e.target.files,"imgshowHamburguesa",75,75,'updImagenes_Hamburguesa')}  />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <img src="" alt="" id="imgshowCloseBlackMarket" height="50" />   
                                                                        <label style ={{marginLeft:10}} className=" control-label">CloseBlackMarket </label>                                                       
                                                                        <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_CloseBlackMarket" 
                                                                               id="updImagenes_CloseBlackMarket" required="required" max="7" 
                                                                               style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                               onChange ={(e) => validOnChange(e.target.files,"imgshowCloseBlackMarket",18,18,'updImagenes_CloseBlackMarket')}  />
                                                                    </div>
                                                                </div>
                                                            </div>    
                                                        </div>
                                                    </div>    
                                                </div>                                                 
                                            </form>
                                                    <div className="contBlock">
                                                        <div className="row p-t-20">
                                                            <div className="col-md-12">
                                                                <label className=" control-label bold">Redes sociales </label>
                                                                <div className="form-group">                                                                    
                                                                    <div className="groupInputSocial">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <div className="form-group"> 
                                                                                    <input type="text" className="form-control urlsocial" placeholder="Url Facebook" name="facebook"  id="facebook"  max="120" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <div className="form-group">                                                                                 
                                                                                    <input type="text" className="form-control urlsocial" placeholder="Url Youtube"  name="youtube"  id="youtube"   max="120" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <div className="form-group"> 
                                                                                    <input type="text" className="form-control urlsocial" placeholder="Url Instagram" name="instagram"  id="instagram" max="120" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <div className="form-group"> 
                                                                                    <input type="text" className="form-control urlsocial" placeholder="Url Twitter"  name="twitter"  id="twitter"   max="120" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <div className="form-group"> 
                                                                                    <input type="text" className="form-control urlsocial" placeholder="Url Linkedin"  name ="linkedin" id="linkedin"  max="120" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <div className="form-group"> 
                                                                                    <input type="text" className="form-control urlsocial" placeholder="Url WhatsApp"  name="whatsapp"  id="whatsapp"  max="120" />     
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> 
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                <div className="row p-t-20">                                                          
                                                    <div className="col-md-12">
                                                        <div className="form-group form-group-faqs ">                                                                    
                                                            <label className=" control-label bold">Preguntas frecuentas </label>
                                                            <a href="#nogo" className="addCatFaqs">Agregar Categoría pregunta</a>
                                                            <div className="itemfaqs">
                                                                <button class="addCatFaqsmenosppal">-</button>
                                                                <div className="col-md-6">
                                                                   <input type="text" className="form-control tfaqs" placeholder="Nombre categoría"    max="120" />                                                                    
                                                                </div>
                                                                <button className="addCatFaqsmas">Agregar Pregunta y respuesta</button>
                                                                <div className="separador"></div>
                                                                <div className="groupInputFaqs"> 
                                                                    <button class="addCatFaqsmenos">-</button>                                                                   
                                                                    <input type="text" className="form-control qfaqs" placeholder="Pregunta"     max="120" /> 
                                                                    <input type="text" className="form-control rfaqs" placeholder="Respuesta"    max="255" />    
                                                                </div>   
                                                            </div>                                                               
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div className="row p-t-20">
                                                    <label className="col-md-6 control-label bold">Look and Feel Base de la App</label>    
                                                    <div className="separador"></div> 
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Estado</label>                                                        
                                                            <select name="onOffLookBase" className="form-control" id="updonOffLookBase">
                                                                <option value="1">Activo</option>
                                                                <option value="2">Inactivo</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>    

                                            <div className="form-group">
                                                <div className="col-sm-offset-2 col-sm-10">
                                                    <button type="button" className="btn btn-default btnupdate">Ingresar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )}
}

export default Marketplace;