import React, {Component} from 'react'
import { endSession } from '../../assets/js/GeneralScript';
import Const from '../utils/defaultConstant'

class InputSectors extends Component {

    constructor(props){
        super(props);
        this.state = {
            items:[]
        }
    }

    componentDidMount(){
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "/api/typedocument", { // ok
            headers: { 
                'Authorization': token,
            },
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success))
                    this.setState({ items: result.data })
            })
    }

    render() { 

        const {  items } = this.state

        return  (
            <React.Fragment>                
                <div className="col-md-2">
                    <div className="form-group">
                        <label className=" control-label">Tipo de Doc</label>
                        <select className="form-control" id="updtypeDocument" name="typeDocument">      
                            {items.map(item => (
                                <option key={item.id} value={item.id} >{item.name}</option>
                            ))}                                          
                        </select>                                                              
                    </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default InputSectors