import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import { sha256 } from 'js-sha256';
import Sidebar from '../sidebar/Sidebar'
import Const from '../utils/defaultConstant'
import MaterialTable from 'material-table';
import InputFabdist from '../atoms/InputFabdist'
import InputStatus from '../atoms/InputStatusCedis'
import InputMarketplaceWhite from "../atoms/InputMarketplaceWhite";
import InputMarketplaceAso from '../atoms/InputMarketplaceAso'
import InputMarketplaceWhite_b2c from "../atoms/InputMarketplaceWhite_b2c";
import InputMarketplaceAso_b2c from '../atoms/InputMarketplaceAso_b2c'
import InputSectors from '../atoms/InputSectors'
import langEs from '../utils/langEs'
import $ from 'jquery';
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import {alertaGeneral,closeAlertGeneral,alertaConfirm, endSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar');

class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            /* error: null, */
            items: []
        };
    }

    componentDidMount() { 
        /* const idCedi = sessionStorage.getItem("idCedi") */
        var el = document.getElementById('mggAlert');
        var close = document.querySelector(".closeConfirm")
        var btnAlertConfirm = document.querySelector("#btnAlertConfirm")

        function formConfirmDelete() {
            var nid = document.getElementById("btnAlertConfirm").getAttribute("data-nid")
            var oldImg1 = document.getElementById("btnAlertConfirm").getAttribute("data-nimg1")
            if (nid >= 1) {
                document.querySelector('#mggAlertConfirm').style.display = 'none'
                formDelete(nid,oldImg1)
            }
        }

        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }
        if (close) {
            close.addEventListener("click", closeAlertGeneral);
        }
        if (btnAlertConfirm) {
            btnAlertConfirm.addEventListener("click", formConfirmDelete);
        }

        async function formDelete(id,oldImg1) {
            const inicioImg = oldImg1.lastIndexOf('/') + 1
            const finImg = oldImg1.length
            const cadenaExtraida = oldImg1.substring(inicioImg, finImg);
            if (id >= 1) {
                var datos = {
                    id: id,
                    oldImg1: cadenaExtraida
                }
                let token = sessionStorage.getItem('token')
                await fetch(Const.urlrest + "/api/cedi/destroy", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },    
                    method: "DELETE",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            alertaGeneral("Registro  Eliminado");
                            window.location.reload(false);
                        }
                    }
                )
                .catch(erro => alertaGeneral("Problemas de conexión"))
            } else {
                alertaGeneral("Datos  incorrectos");
            }
        }

        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "/api/cedi",{ // ok
           method: 'GET', 
           headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token,
        },
        })
        .then(response => response.json())
        .then(
            (result) => {
                console.log("**************** result : ", result)
                if (endSession(result.success)) {
                    result.data.forEach(function (element){
                        if (element.status == 1) {
                                element.actCedi = "Activo"
                                element.status = 1
                        }else{
                            element.actCedi = "Inactivo"
                            element.status = 2
                        }
                    })
                    this.setState({
                        items: result.data
                    })    
                }
            }
        )
        .catch(erro => alertaGeneral("Problemas de conexión"))

        function validURL(str) {
            var pattern = new RegExp('^(https?:\\/\\/)?' +
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                '((\\d{1,3}\\.){3}\\d{1,3}))' +
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
                '(\\?[;&a-z\\d%_.~+=-]*)?' +
                '(\\#[-a-z\\d_]*)?$', 'i');
            return !!pattern.test(str);
        }

        function validarEmail(valor) {
            if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(valor)) {
                return 1
            } else {
                alertaGeneral("La dirección de email es incorrecta!.");
                return 0
            }
        }

        function updateForm() {
            const registerValid = sessionStorage.getItem("registerValid")   
            if (registerValid == "true") {
                /* let actCedi = $("#activocedi").val() */
                let name = document.getElementById("updname").value;
                let lat = document.getElementById("updlat").value;
                let lng = document.getElementById("updlng").value;
                let radio = parseInt(document.getElementById("updradio").value);
                let weight = document.getElementById("updweight").value;
                let width = document.getElementById("updwidth").value
                let height = document.getElementById("updheight").value
                let nroLicInSite = document.getElementById("updnroLicInSite").value
                let emailAdmin = document.getElementById("updemailAdmin").value
                let emailServiceClient = document.getElementById("updemailServiceClient").value
                let emailvalidoClient = validarEmail(emailServiceClient)
                let emailvalidoAdmin = validarEmail(emailAdmin)
                let description = document.getElementById("upddescription").value
                let address = document.getElementById("updaddress").value
                let nameAdmin = document.getElementById("updnameAdmin").value
                let phoneAdmin = document.getElementById("updphoneAdmin").value
                let numbersImages = parseInt(document.getElementById("updnumbersImages").value)
                let balanceNotifications = document.getElementById("updbalanceNotifications").value
                let textTyc = document.getElementById("updtextTyc").value
                let textPd = document.getElementById("updtextPd").value
                let textAutoriza = document.getElementById("updtextAutoriza").value
                /* let cediHide = document.getElementById("updcediHide").value */
                let slogan = document.getElementById("updslogan").value

                    if ($("#updidmarketplaceaso").val() >= 1 || $("#updmarketplace").val() >= 1) {
                        if (emailvalidoClient == "1" && emailvalidoAdmin == "1") {
                            if (lat != "" && lng != "" && radio >= 1) {  
                                let social = []
                                /* let error = 0; */
                                let coordinates = {
                                    'lat': lat,
                                    'lng': lng,
                                    'radio': radio
                                }
                                $(".urlsocial").each(function (index) {
                                    if (this.value != "") {
                                        let url = validURL(this.value)
                                        if (url) {
                                            social.push({
                                                'name': this.id,
                                                'url': this.value
                                            })
                                        } /* else {
                                            error = 1;
                                        } */
                                    }
                                })
                                $("#updsocialRef").val(JSON.stringify(social))
                                $("#updcoordinates").val(JSON.stringify(coordinates))
                                if (name.length >= 4 &&
                                    description.length >= 5 &&
                                    address.length >= 5 &&
                                    nameAdmin.length >= 5 &&
                                    phoneAdmin.length >= 5 &&
                                    balanceNotifications.length >= 1 &&
                                    textTyc.length >= 5 &&
                                    numbersImages >= 1 &&
                                    textPd.length >= 5 &&
                                    textAutoriza.length >= 5 &&
                                    /* cediHide.length >= 1 && */
                                    weight.length >= 1 &&
                                    width.length >= 1 &&
                                    height.length >= 1 &&
                                    slogan.length >= 4
                                ) {
                                    let token = sessionStorage.getItem('token')
                                    const data = new FormData(document.getElementById('formulario'));
                                    fetch(Const.urlrest + "/api/cedi/update", { // ok
                                        method: "PUT",
                                        headers: {
                                            'Authorization': token,
                                        },
                                        body: data
                                    })
                                    .then(response => response.json())
                                    .then(
                                        (result) => {
                                            if (endSession(result.success)) {
                                                alertaGeneral("Registro  Actualizado");
                                                window.scrollTo(0, 0);
                                                document.querySelector('.formupdate').style.display = 'none'
                                                document.getElementById("formulario").reset();
                                                window.location.reload(false);    
                                            }
                                        }
                                    )
                                    .catch(error => alertaGeneral("Problemas de conexión"))
                                } else {
                                    alertaGeneral("Revisa los campos, todos son obligatorios");
                                }
                            } else {
                                alertaGeneral("Revisa las coordenadas");
                            }
                        } else {
                            alertaGeneral("Por favor ingresa un email valido");
                        }
                    } else {
                        alertaGeneral("Es necesario ingresar si es asociativo o marca blanca");
                    }
            } else
                alertaGeneral("La imagen seleccionada no es valida, no se permite la operación de Actualización")
        }

        var el2 = document.getElementById('btnupdate');
        if (el2) {
            el2.addEventListener("click", updateForm);
        }

        /* $('#updpassword').val(sha256(document.getElementById("updpassword").value)) */
        $("#updpassword").on("change",function(e) {
            let var_pass = sha256(document.getElementById("updpassword").value)
            $('#updpassword').val(var_pass) 
        })
    }
    
    render(){
      
        function formEdit(id) {
            sessionStorage.setItem("registerValid", true)  
            window.scrollTo(0, 0);
            let token = sessionStorage.getItem('token')
            document.querySelector('.formupdate').style.display = 'block'
            fetch(Const.urlrest + "/api/cedi/read", { // ok
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify({
                    id: id
                })
            })
            .then(response => response.json())
            .then(
                (response) => {
                    if (endSession(response.success)) {
                        $("#actCedi option[value=" + response.data.ActMarketplace + "] ").attr('selected', 'selected');
                        document.getElementById("updid").value = response.data.id
                        document.getElementById("updname").value = response.data.name
                        document.getElementById("updnameAdmin").value = response.data.nameAdmin
                        document.getElementById("updphoneAdmin").value = response.data.phoneAdmin
                        document.getElementById("updemailAdmin").value = response.data.emailAdmin
                        document.getElementById("updmethodEmail").value = response.data.methodEmail
                        document.getElementById("updnumbersImages").value = response.data.numbersImages
                        document.getElementById("updbalanceNotifications").value = response.data.balanceNotifications
                        document.getElementById("updorder").value = response.data.order
                        document.getElementById("updtextTyc").value = response.data.textTyc
                        document.getElementById("updtextPd").value = response.data.textPd
                        document.getElementById("updtextAutoriza").value = response.data.textAutoriza
                        document.getElementById("updemailServiceClient").value = response.data.emailServiceClient
                        document.getElementById("updwidth").value = response.data.width
                        document.getElementById("updheight").value = response.data.height
                        document.getElementById("updnroLicInSite").value = response.data.nroLicInSite
                        document.getElementById("updweight").value = response.data.weight
                        document.getElementById("updaddress").value = response.data.address
                        document.getElementById("upddescription").value = response.data.description
                        document.getElementById("updslogan").value = response.data.slogan
                        document.getElementById("imgshow").src = response.data.img
                        document.getElementById("updonOffLook").src = response.data.onOffLook
                        document.getElementById("updonOffListPrices").src = response.data.onOffListPrices
                        document.getElementById("updonOffNotificaciones").src = response.data.onOffNotificaciones
                        document.getElementById("updonOffImgPublicidad").src = response.data.onOffImgPublicidad
                        let cadena = response.data.img
                        const inicioImg = cadena.lastIndexOf('/') + 1
                        const finImg = cadena.length
                        const cadenaExtraida = cadena.substring(inicioImg, finImg);
                        document.getElementById("updOldImg").value = cadenaExtraida
    
                        $('#updpassword').val(response.data.password)
                        $("#updstatus option[value=" + response.data.status + "] ").attr('selected', 'selected');
                        $("#updonOffLook option[value=" + response.data.onOffLook + "] ").attr('selected', 'selected');
                        $("#updonOffListPrices option[value=" + response.data.onOffListPrices + "] ").attr('selected', 'selected');
                        $("#updonOffNotificaciones option[value=" + response.data.onOffNotificaciones + "] ").attr('selected', 'selected');
                        $("#updonOffImgPublicidad option[value=" + response.data.onOffImgPublicidad + "] ").attr('selected', 'selected');
                        $("#updvitrinaB2bStatus option[value=" + response.data.vitrinaB2bStatus + "] ").attr('selected', 'selected');
                        $("#updvitrinaB2cStatus option[value=" + response.data.vitrinaB2cStatus + "] ").attr('selected', 'selected');
                        $("#updsectors option[value=" + response.data.sectors + "] ").attr('selected', 'selected');
                        $("#updidFabDist option[value=" + response.data.idFabDist + "] ").attr('selected', 'selected');
                        $("#updactCedi option[value=" + response.data.actCedi + "] ").attr('selected', 'selected');
                        $("#updidmarketplaceaso option[value=" + response.data.idMarketplaceASo + "] ").attr("selected", "selected");
                        $("#updmarketplace option[value=" + response.data.idMarketplace + "] ").attr('selected', 'selected');
                        $("#updidmarketplaceaso_b2c option[value=" + response.data.idMarketplaceASo_b2c + "] ").attr("selected", "selected");
                        $("#updmarketplace_b2c option[value=" + response.data.idMarketplace_b2c + "] ").attr('selected', 'selected');
    
                        if (response.data.socialRef){
                            let socialRef = response.data.socialRef
                            socialRef.forEach(element => {
                                document.getElementById(element.name).value = element.url
                            })
                        }
                    
                        let coordenadas = JSON.parse(response.data.coordinates)
                        for (var clave in coordenadas) {
                            if (coordenadas.hasOwnProperty(clave)) {
                                document.getElementById("upd" + clave).value = coordenadas[clave]
                            }
                        }    
                    }
                }
            )
            .catch(erro => alertaGeneral("Problemas de conexión **"))      
        }

        var permisos = []
        let access = JSON.parse(sessionStorage.getItem('access'))
        access.forEach(function (element) {
            if (element == 29) {
                permisos.push({
                    icon: 'edit',
                    iconProps: {
                        style: {
                            color: "#00569b"
                        }
                    },
                    tooltip: 'Edit',
                    onClick: (event, rowData) => formEdit(rowData.id)
                })
            }
            if (element == 30) {
                permisos.push({
                    icon: 'delete',
                    iconProps: {
                        style: {
                            color: "#ff5722"
                        }
                    },
                    tooltip: 'Delete User',
                    onClick: (event, rowData) => alertaConfirm(rowData.id, rowData.img)
                })
            }
        })        

        function validOnChange(filex) {
            let uploadFile = filex[0]
            let output = document.getElementById('imgshow');
            output.src = URL.createObjectURL(filex[0]);
            output.onload = function() {
                if ((output.naturalWidth.toFixed(0) != 498) && (output.naturalHeight.toFixed(0) != 120) && (uploadFile.size > 30001)) {
                    document.getElementById("imgshow").src = ''
                    sessionStorage.setItem("registerValid","false")
                    alertaGeneral("La Imagen seleccionada no cumple con la medida de 498 x 120 pixels y/o peso <= 30 KB")
                } else
                    sessionStorage.setItem("registerValid","true")
                URL.revokeObjectURL(output.src) // free memory
            }
        }

        const { items } = this.state;

        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <AlertConfirmation />
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-12 align-self-center">
                            <h3 className="text-primary">Cedis</h3> 
                        </div>
                    </div>
                    <div className="container-fluid formupdate" id="formupdate">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-title">
                                        <h4>Actualizar </h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="horizontal-form">
                                            <div className="form-horizontal" id="formcreate" >
                                                <form id="formulario">
                                                    <div className="form-group">
                                                        <input type="hidden" className="form-control" placeholder=""  id="updid" required="required" name="id"  max="30" />
                                                        <input type="hidden" className="form-control" placeholder=""  id="updOldImg" required="required" name="oldImg"  max="30" />
                                                        <input type="hidden" name="coordinates"  id="updcoordinates" />
                                                        <input type="hidden" name="socialRef"   id="updsocialRef"  />                                   
                                                        <div className="row p-t-20">
                                                            <InputSectors />                                                                  
                                                            <InputMarketplaceAso />
                                                            <InputMarketplaceWhite />  
                                                            <InputMarketplaceAso_b2c />
                                                            <InputMarketplaceWhite_b2c />  
                                                            <InputStatus />   
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Vitrina B2B</label>                                                        
                                                                    <select className="form-control" id="updvitrinaB2bStatus" name="vitrinaB2bStatus">      
                                                                        <option value="1" >Activo</option>   
                                                                        <option value="0" >Inactivo</option>                                                                        
                                                                    </select>   
                                                                </div>
                                                            </div>                                           
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Vitrina B2C</label>                                                        
                                                                    <select className="form-control" id="updvitrinaB2cStatus" name="vitrinaB2cStatus">      
                                                                        <option value="1" >Activo</option>   
                                                                        <option value="0" >Inactivo</option>                                                                        
                                                                    </select>   
                                                                </div>
                                                            </div>                                           
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">{langEs.ACTIVE_CEDI}</label>                                                        
                                                                    <select className="form-control" id="updactCedi" name="actCedi">      
                                                                        <option value="1" >Activo</option>   
                                                                        <option value="0" >Inactivo</option>                                                                        
                                                                    </select>   
                                                                </div>
                                                            </div>
                                                            <InputFabdist />

                                                        </div>  
                                                        <div className="row p-t-20">
                                                        <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">{langEs.NAME} </label>
                                                                    <input type="text" className="form-control" placeholder="" name="name" id="updname" required="required"  max="60" />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">{langEs.ADDRESS} </label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="address" id="updaddress" required="required"   />
                                                                </div>
                                                            </div>                                                
                                                            
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Slogan</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="slogan"  id="updslogan" required="required"   />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className=" control-label">{langEs.ACTIVE_DESCRIP}</label>                                                        
                                                                    {/* <input type="text" className="form-control" placeholder="" name="description"  id="upddescription" required="required"   /> */}
                                                                    <textarea name="description" id="upddescription"></textarea>

                                                                </div>
                                                            </div>
                                                        </div>                      
                                                        <div className="row p-t-20">
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Logo</label> 
                                                                    <img src="" id="imgshow" height="50" />                                                        
                                                                    <input type="file" style = {{color: "transparent", border: "none"}} className="form-control" placeholder="" name="img" id="updimg" required="required" accept="image/png" onChange ={(e) => validOnChange(e.target.files,"imgshow","updimg")} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className=" control-label">On/Off Look</label>                                                        
                                                                    <select className="form-control" id="updonOffLook" name="onOffLook">      
                                                                        <option value="1" >Activo</option>   
                                                                        <option value="0" >Inactivo</option>                                                                        
                                                                    </select>   
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className=" control-label">On/Off ListPrices</label>                                                        
                                                                    <select className="form-control" id="updonOffListPrices" name="onOffListPrices">      
                                                                        <option value="1" >Activo</option>   
                                                                        <option value="0" >Inactivo</option>                                                                        
                                                                    </select>   
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className=" control-label">On/Off Notificaciones</label>                                                        
                                                                    <select className="form-control" id="updonOffNotificaciones" name="onOffNotificaciones">      
                                                                        <option value="1" >Activo</option>   
                                                                        <option value="0" >Inactivo</option>                                                                        
                                                                    </select>   
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className=" control-label">On/Off Img Publicidad</label>                                                        
                                                                    <select className="form-control" id="updonOffImgPublicidad" name="onOffImgPublicidad">      
                                                                        <option value="1" >Activo</option>   
                                                                        <option value="0" >Inactivo</option>                                                                        
                                                                    </select>   
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="row p-t-20">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Nombre Administrador </label>
                                                                    <input type="text" className="form-control" placeholder="" name="nameAdmin" id="updnameAdmin" required="required"  max="60" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Tel Administrador</label>                                                        
                                                                    <input type="number" className="form-control" placeholder="" name="phoneAdmin" id="updphoneAdmin" required="required"   />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row p-t-20">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Email Administrador </label>
                                                                    <input type="email" className="form-control" placeholder="" name="emailAdmin"  id="updemailAdmin" required="required"  max="60" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Password</label>
                                                                    <input type="password" className="form-control" placeholder="******"
                                                                        id="updpassword" required="required" name="password" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Número de images</label>
                                                                    <input type="number" className="form-control" name="numbersImages" placeholder=""
                                                                        id="updnumbersImages" required="required"
                                                                        max="1" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="contBlock">
                                                            <div className="row p-t-20">
                                                                <label className="col-md-6 control-label bold">Zona de atención del CEDI</label>
                                                            <div className="separador">
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className=" control-label">Latitud</label>
                                                                <input type="text" step="0.01" className="form-control control-coo" name="latitud" placeholder="Latitud"  id="updlat" required="required"  max="30" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className=" control-label">Longitud</label>
                                                                <input type="text" step="0.01" className="form-control control-coo" name="longitud" placeholder="Longitud"  id="updlng" required="required"  max="30" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className=" control-label">Km</label>
                                                                <input type="number" step="0.01" className="form-control  control-radio" name="radio" placeholder="Radio"  id="updradio" required="required"  max="30" />
                                                            </div>
                                                        </div>                                                
                                                    </div>
                                                </div>                                            
                                                <div className="row p-t-20">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className=" control-label">Método de Entrega del pedido</label>
                                                                <select className="form-control" id="updmethodEmail" name="methodEmail">      
                                                                    <option value="1" >Email</option>   
                                                                    <option value="2" >Webservices</option>                                                                        
                                                                </select>   
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className=" control-label">Email servicio al Cliente</label>
                                                            <input type="email" className="form-control" placeholder=""
                                                                id="updemailServiceClient" required="required" name="emailServiceClient"
                                                                max="60" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row p-t-20">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className=" control-label">Saldo Notificaciones</label>
                                                            <input type="number" className="form-control" placeholder=""
                                                                id="updbalanceNotifications" required="required" name="balanceNotifications"
                                                                max="3" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className=" control-label">Orden de Presentación</label>
                                                            <input type="text" className="form-control" style={{marginLeft:5, width:50}} placeholder="99" 
                                                                id="updorder" required="required" name="order"
                                                                max="2" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row p-t-20">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className=" control-label">Términos y condiciones</label>
                                                            <textarea name="textTyc" id="updtextTyc"></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className=" control-label">Política de datos </label>
                                                            <textarea name="textPd" id="updtextPd"></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className=" control-label">Autorización Tratamiento de Datos </label>
                                                            <textarea name="textAutoriza" id="updtextAutoriza"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="contBlock">
                                                <div className="row p-t-20">
                                                    <div className="col-md-12">
                                                        <label className=" control-label bold">Redes sociales </label>
                                                        <div className="form-group">                                                                    
                                                            <div className="groupInputSocial">
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <div className="form-group"> 
                                                                            <input type="text" className="form-control urlsocial" placeholder="Url Facebook" name="facebook"  id="facebook"  max="120" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group"> 
                                                                            <input type="text" className="form-control urlsocial" placeholder="Url Youtube"  name="youtube"  id="youtube"   max="120" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group"> 
                                                                            <input type="text" className="form-control urlsocial" placeholder="Url Instagram" name="instagram"  id="instagram" max="120" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group"> 
                                                                            <input type="text" className="form-control urlsocial" placeholder="Url Twitter"  name="twitter"  id="twitter"   max="120" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group"> 
                                                                            <input type="text" className="form-control urlsocial" placeholder="Url Linkedin"  name ="linkedin" id="linkedin"  max="120" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group"> 
                                                                            <input type="text" className="form-control urlsocial" placeholder="Url WhatsApp"  name="whatsapp"  id="whatsapp"  max="120" />     
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                            <div className="contBlock">
                                                <div className="row p-t-20">
                                                    <label className=" col-md-6 control-label bold">Características de las fotos que puede subir el CEDI</label> 
                                                    <div className="separador"></div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className=" control-label">Peso en kilobites</label>                                                                
                                                            <input type="number" className="form-control" placeholder="" name="weight" id="updweight" required="required"  />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className=" control-label">Ancho en pixeles</label>                                                                
                                                            <input type="number" className="form-control" placeholder="" name="width" id="updwidth" required="required"  />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className=" control-label">Alto pixeles </label>                                                                
                                                            <input type="number" className="form-control" placeholder="" name="height" id="updheight" required="required"  />
                                                        </div>
                                                    </div>                                                                
                                                </div>
                                            </div>
                                            <div className="contBlock">
                                                <div className="row p-t-20">
                                                    <label className=" col-md-6 control-label bold">App TAT</label> 
                                                    <div className="separador"></div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className=" control-label">Nro de Licencias disponibles</label>                                                                
                                                            <input type="number" className="form-control" placeholder="" name="nroLicInSite" id="updnroLicInSite" required="required"  />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-offset-2 col-sm-10">
                                                    <button type="button" className="btn btn-default btnupdate" id="btnupdate">Actualizar</button>
                                                </div>
                                            </div>
                                        </form> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">                                    
                                        <div className="table-responsive m-t-40">                                            
                                            <MaterialTable
                                                title="Datos"
                                                columns={[
                                                    { title: 'Id', field: 'id' },
                                                    { title: ' Estado Plataforma', field: 'actCedi' },
                                                    { title: 'Nombre', field: 'name' }
                                                ]}
                                                data = {
                                                    items
                                                }
                                                options={{
                                                    headerStyle: {
                                                        backgroundColor: '#251972',
                                                        color: '#FFF'
                                                    },
                                                    actionsColumnIndex: -1,
                                                    filtering: true,
                                                    search: false
                                                }}
                                                actions={permisos}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                                          
                </div>
            </div>
        )
    }
}

export default Category;