import React, {Component} from 'react'
import { endSession } from '../../assets/js/GeneralScript';
import Const from '../utils/defaultConstant'

class InputSector extends Component {

    constructor(props){
        super(props);
        this.state = {
            items:[]
        }
    }
    componentDidMount(){
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "/api/sectors", { // ok
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
        })
        .then(response => response.json())
        .then(
            (result) => {
                console.log("============ result InputSectors : ", result)
                if (endSession(result.success))
                    this.setState({ items: result.data })
            }
        )
    }

    render() {

        const {  items } = this.state

        return  (
            <React.Fragment>                
                <div className="col-md-3">
                    <div className="form-group">
                        <label className=" control-label">Sector</label>
                            <select className="form-control" id="updsectors" name="sectors">      
                            {items.map(item => (
                                <option value={item.id} >{item.name}</option>
                            ))}                                          
                            </select>                                                              
                        </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default InputSector