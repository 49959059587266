import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import MaterialTable from 'material-table';
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import {alertaGeneral,closeAlertGeneral,alertaConfirm, endSession} from '../../assets/js/GeneralScript'
document.body.classList.add('fix-sidebar');

class Paymentmethods extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    componentDidMount() {
        
        var el = document.getElementById('mggAlert');
        var close = document.querySelector(".closeConfirm")
        var btnAlertConfirm = document.querySelector("#btnAlertConfirm")

        function formConfirmDelete() {
            var nid = document.getElementById("btnAlertConfirm").getAttribute("data-nid")
            if (nid >= 1) {
                document.querySelector('#mggAlertConfirm').style.display = 'none'
                formDelete(nid)
            }
        }
        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }
        if (close) {
            close.addEventListener("click", closeAlertGeneral);
        }
        if (btnAlertConfirm) {
            btnAlertConfirm.addEventListener("click", formConfirmDelete);
        }
        
        function formDelete(id) {
            if (id >= 1) {
                var datos = {
                    id: id
                }               
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "/api/paymentmethods/destroy", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "DELETE",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            if(result.data == "1"){
                                alertaGeneral("Registro  Eliminado");
                                window.location.reload(false);
                            }else{
                                alertaGeneral("No se puede eliminar"); 
                            }     
                        }
                    }
                )
            } else {
                alertaGeneral("Datos  incorrectos");
            }
        }
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "/api/paymentmethods", { // ok
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            method: 'GET',
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success))
                    this.setState({items: result.data })
            }
        )

        $(document).off().on('click', '.btnupdate', function () { 
            let id = $("#updid").val();
            let name = $("#updname").val();
            let idCedi = $("#formupdate #updcedis").val();
            if (name.length >= 4) {
                var datos = {
                    id: id,
                    name: name,
                    idCedi: idCedi
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "/api/paymentmethods/update", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "PUT",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            alertaGeneral("Registro  Actualizado");
                            window.location.reload(false);    
                        }
                    }
                )
            } else {
                alertaGeneral("Datos  incorrectos");
            }
        })
    }
    render() {           
        function formEdit(id) {
            window.scrollTo(0, 0);
            document.querySelector('.formupdate').style.display = 'block'
            let token = sessionStorage.getItem('token')
            fetch(Const.urlrest + "/api/paymentmethods/read", { // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify({
                    id: id
                })
            })
            .then(response => response.json())
            .then(
                (result) => {
                    if (endSession(result.success)) {
                        $("#updid").val(result.data.id)
                        $("#updname").val(result.data.name)       
                    }
                }
            )
        }

        var permisos = []
        let access = JSON.parse(sessionStorage.getItem('access'))
        access.forEach(function (element) {
            if (element == 2) {
                permisos.push({
                    icon: 'edit',
                    iconProps: {
                        style: {
                            color: "#00569b"
                        }
                    },
                    tooltip: 'Edit',
                    onClick: (event, rowData) => formEdit(rowData.id)
                })
            }
            if (element == 3) {
                permisos.push({
                    icon: 'delete',
                    iconProps: {
                        style: {
                            color: "#ff5722"
                        }
                    },
                    tooltip: 'Delete User',
                    onClick: (event, rowData) => alertaConfirm(rowData.id)
                })
            }
        })
        const {  items } = this.state;
        
        return ( 
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <AlertConfirmation />
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-12 align-self-center">
                            <h3 className="text-primary">Métodos de Pago</h3> 
                        </div>
                    </div>
                    <div  className="container-fluid formupdate"  id="formupdate">
                        <div  className="row">
                            <div  className="col-lg-12">
                                <div  className="card">
                                    <div  className="card-title">
                                        <h4>Actualizar </h4>
                                    </div>
                                    <div  className="card-body">
                                        <div  className="horizontal-form">
                                            <div  className="form-horizontal" id="formcreate" >
                                                <div  className="form-group">
                                                    <input type="hidden"  className="form-control" placeholder="" name="name" id="updid" required="required"  max="30" />
                                                    <label  className="col-sm-2 control-label">Nombre </label>
                                                    <div  className="col-sm-4">
                                                        <input type="text"  className="form-control" placeholder="" name="name" id="updname" required="required"  max="30" />
                                                    </div>
                                                </div>
                                                <div  className="form-group">
                                                    <div  className="col-sm-offset-2 col-sm-10">
                                                        <button type="button"  className="btn btn-default btnupdate" >Actualizar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">  
                                        <div className="table-responsive m-t-40">                                                                                                 
                                            <MaterialTable
                                                title="Datos"
                                                columns={[
                                                    { title: 'Id', field: 'id' },
                                                    { title: 'Nombre', field: 'name' }
                                                ]}
                                                data = { items }
                                                options={{
                                                    headerStyle: {
                                                        backgroundColor: '#251972',
                                                        color: '#FFF'
                                                    },
                                                    actionsColumnIndex: -1,
                                                    filtering: true,
                                                    search: false
                                                }}
                                                actions = {
                                                    permisos
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>          
                    </div>    
                </div>
            </div>            
        )
    }
}

export default Paymentmethods;