import React, {Component} from 'react'
import InputTypeMarketplace from '../atoms/InputTypeMarketplace'
import InputTypeDocument from '../atoms/InputTypeDocument'
import InputSectors from '../atoms/InputSectors'
import InputStatusMarket from '../atoms/InputStatusMarket'
import langEs from '../utils/langEs'
import {alertaGeneral} from '../../assets/js/GeneralScript'

class updateData extends Component {
    render() {

        function validOnChange(filex, imgshow,ancho,alto) {
            let output = document.getElementById(imgshow);
            output.src = URL.createObjectURL(filex[0]);
            output.onload = function() {
                if ((output.naturalWidth.toFixed(0) > ancho) || (output.naturalHeight.toFixed(0) > alto) || (filex[0].name.length >= 46)  ) {
                    document.getElementById(imgshow).src = ''
                    sessionStorage.setItem("registerValid","false")
                    alertaGeneral("La Imagen seleccionada no cumple con la medida mínima proporcional de ancho: "+ancho+" x alto: "+alto+" pixels, o la longitud del nombre es mayor a 46")
                } else
                    sessionStorage.setItem("registerValid","true")
                URL.revokeObjectURL(output.src) // free memory
            }
        }

        return  (
            <React.Fragment>                   
                <div className="container-fluid formupdate" id="formupdate">                       
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-title">
                                    <h4>Actualizar </h4>
                                </div>
                                <div className="card-body">
                                    <div className="horizontal-form">
                                        <form id="formulario">
                                            <div className="form-horizontal" id="formcreate" >
                                                <input type="hidden" className="form-control" placeholder="" name="name" id="updid"    />
                                                <div className="row p-t-20">
                                                    <InputStatusMarket />
                                                    <InputTypeMarketplace /> 
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label className=" control-label">{langEs.NAME_MARKETPLACE} </label>
                                                            <input type="text" className="form-control" placeholder=""  id="updname"   max="30" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className="control-label">Versión Marketplace</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="versMarketplace" id="updversMarketplace"   step="0.01"  max="30" />
                                                        </div>                                                            
                                                    </div>
                                                </div>                                           
                                                <div className="row p-t-20">
                                                    <div className="col-md-9">
                                                        <div className="form-group">
                                                            <label className=" control-label">Url App Play Store</label>
                                                            <input type="text" className="form-control" placeholder="" name="linkAppStore" id="updlinkAppStore"   max="250" />
                                                        </div> 
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className="control-label">Tipo de Negocio </label>                                                        
                                                            <select name="typeBusiness" className="form-control" id="updtypeBusiness">
                                                                <option value="1">B2B</option>
                                                                <option value="2">B2C</option>
                                                            </select>
                                                        </div>                                                            
                                                    </div>
                                                    <InputSectors />
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className="control-label">Vitrina Pública</label>                                                        
                                                            <select name="vitrinaMarkeplace" className="form-control" id="updvitrinaMarketplace">
                                                                <option value="1">Activa</option>
                                                                <option value="2">Inactiva</option>
                                                            </select>
                                                        </div>                                                            
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className=" control-label">{langEs.NAME_CLIENT} </label>
                                                            <input type="text" className="form-control" placeholder=""  id="updnameClient"   max="30"  />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row p-t-20">
                                                    <InputTypeDocument />
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className=" control-label">Documento </label>
                                                            <input type="number" className="form-control" placeholder="" name="numberDocument" id="updnumberDocument"   max="10" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className="control-label">Teléfono </label>
                                                            <input type="number" className="form-control" placeholder="" name="updphone" id="updphone"   max="12"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label className=" control-label">Dirección </label>
                                                            <input type="text" className="form-control" placeholder="" name="address" id="updaddress"   max="60" />
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div className="row p-t-20">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className=" control-label">Política de datos </label>
                                                            <textarea name="textPd" id="updtextPd"></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">                                                                    
                                                            <label className=" control-label">Notas Comerciales</label>
                                                            <textarea name="note" id="updnote"></textarea>                                                                 
                                                        </div>
                                                    </div>
                                                </div>    
                                                <div className="row p-t-20">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className=" control-label">Términos y condiciones</label>
                                                            <textarea name="textTyc" id="updtextTyc"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row p-t-20">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className=" control-label">Autorización de Tratamiento de Datos</label>
                                                            <textarea name="textAutoriza" id="updtextAutoriza"></textarea>
                                                        </div>
                                                    </div>
                                                </div>   
                                                <div className="row p-t-20">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className=" control-label">Clave Privada FireBase</label>
                                                            <textarea name="privKeyFireBase" id="updprivKeyFireBase"></textarea>
                                                        </div>
                                                    </div>
                                                </div>   
                                                <div class="contBlock">
                                                    <div className="row p-t-20">
                                                        <label class=" col-md-12 control-label bold">Características de las fotos publicitarias para las App's</label>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className=" control-label">Peso en kilobites </label>                                                                
                                                                <input type="number" className="form-control" placeholder="" name="weight" id="updweight" required="required"  max="10" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className=" control-label">Ancho en pixeles</label>                                                                
                                                                <input type="number" className="form-control" placeholder="" name="width" id="updwidth" required="required"  max="10"  />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className=" control-label">Alto  en pixeles </label>                                                                
                                                                <input type="number" className="form-control" placeholder="" name="height" id="updheight" required="required" max="10" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="contBlock"> 
                                                    <div className="row p-t-20">
                                                        <div className="col-md-12">
                                                            <label className=" control-label bold">Redes sociales </label>
                                                            <div className="form-group">                                                                    
                                                                <div className="groupInputSocial">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url Facebook" name="facebook"  id="facebook"  max="120" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url Youtube"  name="youtube"  id="youtube"   max="120" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url Instagram" name="instagram"  id="instagram" max="120" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url Twitter"  name="twitter"  id="twitter"   max="120" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url Linkedin"  name ="linkedin" id="linkedin"  max="120" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group"> 
                                                                                <input type="text" className="form-control urlsocial" placeholder="Url WhatsApp"  name="whatsapp"  id="whatsapp"  max="120" />     
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> 
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div>
                                                <div className="row p-t-20">
                                                    <div className="col-md-12">
                                                        <div className="form-group form-group-faqs">                                                                    
                                                            <label className=" control-label bold">Preguntas frecuentas </label>
                                                            <a href="#nogo" className="addCatFaqs">Agregar Categoría pregunta</a>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <div className="row p-t-20">
                                                    <label className="col-md-6 control-label bold">Look and Feel Base de la App</label>    
                                                    <div className="separador"></div> 
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Estado</label>                                                        
                                                            <select name="onOffLookBase" className="form-control" id="updonOffLookBase">
                                                                <option value="1">Activo</option>
                                                                <option value="2">Inactivo</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>    
                                            </div>
                                        </form>
                                        {/* ***************** */}
                                        <form id="formularioupdate">
                                            <input type="hidden" className="form-control" placeholder="" name="idMarketplace" id="updidMarketplace" required="required"   /> 
                                            <div className="contBlock">  
                                                <div className="row p-t-20">
                                                    <label className="col-md-6 control-label bold">Principal</label>    
                                                    <div className="separador"></div> 
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PrincipalConst_ColorFondoPantalla" id="updPrincipalConst_ColorFondoPantalla" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Email y Contraseña</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PrincipalConst_ColorFondoInputs" id="updPrincipalConst_ColorFondoInputs" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Borde Email y Contraseña</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PrincipalConst_ColorTextInputBorde" id="updPrincipalConst_ColorTextInputBorde" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Email y Contraseña </label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PrincipalConst_ColorTextoInputs" id="updPrincipalConst_ColorTextoInputs" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Botón "Entrar"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PrincipalConst_ColorBotonEntrar" id="updPrincipalConst_ColorBotonEntrar" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Botón "Entrar"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PrincipalConst_ColorTextBotonEntrar" id="updPrincipalConst_ColorTextBotonEntrar" required="required"   />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Botón "Ubicación"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PrincipalConst_ColorBotonUbicacion" id="updPrincipalConst_ColorBotonUbicacion" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Botón "Ubicación"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PrincipalConst_ColorTextBotonUbicacion" id="updPrincipalConst_ColorTextBotonUbicacion" required="required"   />
                                                        </div>
                                                    </div>
                                                   
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto "Olvidé Mi Contraseña"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PrincipalConst_ColorTextOlvideClave" id="updPrincipalConst_ColorTextOlvideClave" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Botón Fondo "Soy nuevo"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PrincipalConst_ColorBotonNuevoUsu" id="updPrincipalConst_ColorBotonNuevoUsu" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Botón "Soy nuevo"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PrincipalConst_ColorTextBotonNuevoUsu" id="updPrincipalConst_ColorTextBotonNuevoUsu" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Versión</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PrincipalConst_ColorVersion" id="updPrincipalConst_ColorVersion" required="required"   />
                                                        </div>                                                            
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <img src="" id="imgshowImagenSuperior" height="50" />   
                                                            <label style ={{marginLeft:10}} className=" control-label">Imagen Superior</label>                                                        
                                                            <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_ImagenSuperior" 
                                                                id="updImagenes_ImagenSuperior" required="required" style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                onChange ={(e) => validOnChange(e.target.files,"imgshowImagenSuperior",400,72)}  />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <img src="" id="imgshowLogoTienda" height="50" />   
                                                            <label style ={{marginLeft:10}} className=" control-label">Imagen Logo Tienda</label>                                                        
                                                            <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_LogoTienda" 
                                                                id="updImagenes_LogoTienda" required="required" style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                onChange ={(e) => validOnChange(e.target.files,"imgshowLogoTienda",375,145)}  /> 
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <img src="" alt="" id="imgshowUser" height="50" />   
                                                            <label style ={{marginLeft:10}} className=" control-label">Imagen User</label>                                                        
                                                            <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_User" 
                                                                id="updImagenes_User" required="required" style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                onChange ={(e) => validOnChange(e.target.files,"imgshowUser",30,44)}  />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <img src="" alt="" id="imgshowOjo" height="50" />   
                                                            <label style ={{marginLeft:10}} className=" control-label">Imagen Ojo</label>                                                        
                                                            <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Ojo" 
                                                                id="updImagenes_Ojo" required="required" style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                onChange ={(e) => validOnChange(e.target.files,"imgshowOjo",23,23)}  />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="contBlock">  
                                                <div className="row p-t-20">
                                                    <label className="col-md-6 control-label bold">Principal -> Oops, olvidé Contraseña</label>    
                                                    <div className="separador"></div> 
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="OlvideClaveConst_ColorFondo8" id="updOlvideClaveConst_ColorFondo8" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Barra</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="OlvideClaveConst_ColorHeader7" id="updOlvideClaveConst_ColorHeader7" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Barra</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="OlvideClaveConst_FuenteColorHeader6" id="updOlvideClaveConst_FuenteColorHeader6" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="OlvideClaveConst_ColorTexto1" id="updOlvideClaveConst_ColorTexto1" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Email</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="OlvideClaveConst_ColorTextoInput1" id="updOlvideClaveConst_ColorTextoInput1" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Línea Separadora</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="OlvideClaveConst_ColorBordeInput" id="updOlvideClaveConst_ColorBordeInput" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Botón "Enviar"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="OlvideClaveConst_ColorBotonEnviar2" id="updOlvideClaveConst_ColorBotonEnviar2" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Botón "Enviar"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="OlvideClaveConst_ColorTextoBotonEnviar" id="updOlvideClaveConst_ColorTextoBotonEnviar" required="required"   />
                                                        </div>
                                                    </div>
                                                </div>    
                                            </div>  
                                            <div className="contBlock">  
                                                <div className="row p-t-20">
                                                    <label className="col-md-6 control-label bold">Principal -> Soy nuevo quiero Registrarme</label>    
                                                    <div className="separador"></div> 
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="RegistroConst_ColorFondo9" id="updRegistroConst_ColorFondo9" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Barra</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="RegistroConst_ColorHeader9" id="updRegistroConst_ColorHeader9" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Barra</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="RegistroConst_FuenteColorHeader8" id="updRegistroConst_FuenteColorHeader8" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Campos cuando se escribe</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="RegistroConst_ColorPlaceHolder2" id="updRegistroConst_ColorPlaceHolder2" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Líneas Separadoras</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="RegistroConst_ColorLineaInputs" id="updRegistroConst_ColorLineaInputs" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto "Si está de acuerdo ..."</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="RegistroConst_ColorTextoCondiciones2" id="updRegistroConst_ColorTextoCondiciones2" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Términos - Políticas</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="RegistroConst_ColorTextoCondicionesClick2" id="updRegistroConst_ColorTextoCondicionesClick2" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Botón "Continuar"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="RegistroConst_ColorFondoBotonRegistro" id="updRegistroConst_ColorFondoBotonRegistro" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Botón "Continuar"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="RegistroConst_ColorTextoBotonRegistro" id="updRegistroConst_ColorTextoBotonRegistro" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Digitado Campos</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="RegistroConst_ColorTextoInput2" id="updRegistroConst_ColorTextoInput2" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <img src="" alt="" id="imgshowBackArrowMarket" height="50" />   
                                                            <label style ={{marginLeft:10}} className=" control-label">Imagen BackArrowMarket</label>                                                        
                                                            <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_BackArrowMarket" 
                                                                            id="updImagenes_BackArrowMarket" required="required" style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                            onChange ={(e) => validOnChange(e.target.files,"imgshowBackArrowMarket",75,75)}  />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>     
                                            <div className="contBlock">  
                                                <div className="row p-t-20">
                                                    <label className="col-md-6 control-label bold">Principal -> Soy nuevo quiero Registrarme - > Términos y Condiciones</label>
                                                    <div className="separador"></div>    
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="TyCConst_ColorFondoPantalla1" id="updTyCConst_ColorFondoPantalla1" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Barra</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="TyCConst_ColorFondoBarra1" id="updTyCConst_ColorFondoBarra1" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Barra</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="TyCConst_FuenteColorHeader10" id="updTyCConst_FuenteColorHeader10" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Botón y Texto</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="TyCConst_buttonColor" id="updTyCConst_buttonColor" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Selector</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="TyCConst_selectedButtonColor" id="updTyCConst_selectedButtonColor" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Contenido</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="TyCConst_textoModalInfoCedi" id="updTyCConst_textoModalInfoCedi" required="required"   />
                                                        </div>
                                                    </div>                            
                                                </div>
                                            </div>
                                            <div className="contBlock">  
                                                <div className="row p-t-20">
                                                    <label className="col-md-6 control-label bold">Directorio</label>    
                                                    <div className="separador"></div> 
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Superior</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="DirectorioConst_ColorFondo5" id="updDirectorioConst_ColorFondo5" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Inferior</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="DirectorioConst_ColorFondoContenedor1" id="updDirectorioConst_ColorFondoContenedor1" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto "Directorio"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="DirectorioConst_TextoMisProveedores1" id="updDirectorioConst_TextoMisProveedores1" required="required"   />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto "Te Invitamos"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="DirectorioConst_ColorTextoProveedores1" id="updDirectorioConst_ColorTextoProveedores1" required="required"   />                                                                    
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Tarjeta</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="DirectorioConst_CardColorBorde1" id="updDirectorioConst_CardColorBorde1" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Tarjeta</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="DirectorioConst_CardTexto1" id="updDirectorioConst_CardTexto1" required="required"   />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>   
                                            <div className="contBlock">  
                                                <div className="row p-t-20">
                                                    <label className="col-md-6 control-label bold">Directorio -> Selección de Línea de Negocio</label>    
                                                    <div className="separador"></div> 
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="ComunicacionCedisConst_CediColorFondo2" id="updComunicacionCedisConst_CediColorFondo2" required="required"   />
                                                        </div>
                                                    </div>  
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Recuadro</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="ComunicacionCedisConst_modalContentCedi2" id="updComunicacionCedisConst_modalContentCedi2" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Barra</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="ComunicacionCedisConst_ColorHeader4" id="updComunicacionCedisConst_ColorHeader4" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Barra</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="ComunicacionCedisConst_FuenteColorHeader4" id="updComunicacionCedisConst_FuenteColorHeader4" required="required"   />                                                                    
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Empresa</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="ComunicacionCedisConst_colorTextoAdvertenciaCedi1" id="updComunicacionCedisConst_colorTextoAdvertenciaCedi1" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Comentario</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="ComunicacionCedisConst_colorBordeTextoCedi1" id="updComunicacionCedisConst_colorBordeTextoCedi1" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto "Tu comentario"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="ComunicacionCedisConst_ColorPlaceholder1" id="updComunicacionCedisConst_ColorPlaceholder1" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto del Comentario al escribir</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="ComunicacionCedisConst_colorTextoCedi1" id="updComunicacionCedisConst_colorTextoCedi1" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto "Seleccione los .."</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="ComunicacionCedisConst_ColorTextoCondiciones1" id="updComunicacionCedisConst_ColorTextoCondiciones1" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto "Términos y Polit ..."</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="ComunicacionCedisConst_ColorTextoCondicionesClick1" id="updComunicacionCedisConst_ColorTextoCondicionesClick1" required="required"   />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Botón "Confirmar"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="ComunicacionCedisConst_ColorFondoBotonesModal1" id="updComunicacionCedisConst_ColorFondoBotonesModal1" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto "Confirmar"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="ComunicacionCedisConst_ColorTextoBotonesModal1" id="updComunicacionCedisConst_ColorTextoBotonesModal1" required="required"   />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>     
                                            <div className="contBlock">  
                                                <div className="row p-t-20">
                                                    <label className="col-md-6 control-label bold">Menú</label>    
                                                    <div className="separador"></div> 
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="MenuConst_ColorFondoModal3" id="updMenuConst_ColorFondoModal3" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Recuadro Menú</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="MenuConst_ModalOpcionesColorFondo" id="updMenuConst_ModalOpcionesColorFondo" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Textos</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="MenuConst_ModalOpcionesTextoColor" id="updMenuConst_ModalOpcionesTextoColor" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Líneas Separadoras</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="MenuConst_LineaSeparadoraMenuColor" id="updMenuConst_LineaSeparadoraMenuColor" required="required"   />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Botón Cerrar</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="MenuConst_colorFondoBotonCerrar" id="updMenuConst_colorFondoBotonCerrar" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <img src="" alt="" id="imgshowInstagramMarket" height="50" />   
                                                            <label style ={{marginLeft:10}} className=" control-label">Imagen Instagram</label>                                                        
                                                            <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_InstagramMarket" 
                                                                            id="updImagenes_InstagramMarket" required="required" style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                            onChange ={(e) => validOnChange(e.target.files,"imgshowInstagramMarket",75,75)}  />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <img src="" alt="" id="imgshowFacebookMarket" height="50" />   
                                                            <label style ={{marginLeft:10}} className=" control-label">Imagen FaceBook</label>                                                        
                                                            <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_FacebookMarket" 
                                                                            id="updImagenes_FacebookMarket" required="required" style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                            onChange ={(e) => validOnChange(e.target.files,"imgshowFacebookMarket",75,75)}  />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <img src="" alt="" id="imgshowLinkedingMarket" height="50" />   
                                                            <label style ={{marginLeft:10}} className=" control-label">Imagen Linkeding</label>                                                        
                                                            <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_LinkedingMarket" 
                                                                            id="updImagenes_LinkedingMarket" required="required" style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                            onChange ={(e) => validOnChange(e.target.files,"imgshowLinkedingMarket",75,75)}  />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <img src="" alt="" id="imgshowTwitterMarket" height="50" />   
                                                            <label style ={{marginLeft:10}} className=" control-label">Imagen Twitter</label>                                                        
                                                            <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_TwitterMarket" 
                                                                            id="updImagenes_TwitterMarket" required="required" style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                            onChange ={(e) => validOnChange(e.target.files,"imgshowTwitterMarket",75,75)}  />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <img src="" alt="" id="imgshowWhatsappMarket" height="50" />   
                                                            <label style ={{marginLeft:10}} className=" control-label">Imagen Whatsapp</label>                                                        
                                                            <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_WhatsappMarket" 
                                                                            id="updImagenes_WhatsappMarket" required="required" style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                            onChange ={(e) => validOnChange(e.target.files,"imgshowWhatsappMarket",75,75)}  />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <img src="" alt="" id="imgshowYoutubeMarket" height="50" />   
                                                            <label style ={{marginLeft:10}} className=" control-label">Imagen Youtube</label>                                                        
                                                            <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_YoutubeMarket" 
                                                                            id="updImagenes_YoutubeMarket" required="required" style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                            onChange ={(e) => validOnChange(e.target.files,"imgshowYoutubeMarket",75,75)}  />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <img src="" alt="" id="imgshowCloseBlackMarket" height="50" />   
                                                            <label style ={{marginLeft:10}} className=" control-label">Imagen Close Black</label>                                                        
                                                            <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_CloseBlackMarket" 
                                                                            id="updImagenes_CloseBlackMarket" required="required" style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                            onChange ={(e) => validOnChange(e.target.files,"imgshowCloseBlackMarket",18,18)}  />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="contBlock">  
                                                <div className="row p-t-20">
                                                    <label className="col-md-6 control-label bold">Menú -> Unidades de Negocio</label>    
                                                    <div className="separador"></div> 
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="HomeConst_ColorFondo2" id="updHomeConst_ColorFondo2" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Tarjeta</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="HomeConst_CardColorFondo2" id="updHomeConst_CardColorFondo2" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto "Bienvenida .."</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="HomeConst_TextoMisProveedores2" id="updHomeConst_TextoMisProveedores2" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Borde Tarjeta</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="HomeConst_CardColorBorde2" id="updHomeConst_CardColorBorde2" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Nombre Cedi</label>                                                                                                                    <input type="text" className="form-control" placeholder="" name="HomeConst_CardTexto2" id="updHomeConst_CardTexto2" required="required"   />
                                                        </div>                                                                    
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <img src="" alt="" id="imgshowHamburguesa" height="50" />   
                                                            <label style ={{marginLeft:10}} className=" control-label">Imagen Hamburguesa</label>                                                        
                                                            <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Hamburguesa" 
                                                                            id="updImagenes_Hamburguesa" required="required" style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                            onChange ={(e) => validOnChange(e.target.files,"imgshowHamburguesa",75,75)}  />
                                                        </div>
                                                    </div>                                                                
                                                </div>
                                            </div>
                                            <div className="contBlock">  
                                                <div className="row p-t-20">
                                                    <label className="col-md-6 control-label bold">Menú -> Preguntas Frecuentes</label>    
                                                    <div className="separador"></div> 
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PreguntasFrecuentesConst_ColorFondoPantalla20" id="updPreguntasFrecuentesConst_ColorFondoPantalla20" required="required"   />
                                                        </div>
                                                    </div>    
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Barra</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PreguntasFrecuentesConst_ColorHeader8" id="updPreguntasFrecuentesConst_ColorHeader8" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Barra</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PreguntasFrecuentesConst_FuenteColorHeader7" id="updPreguntasFrecuentesConst_FuenteColorHeader7" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Categoría</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PreguntasFrecuentesConst_ColorPreguntaCategoria" id="updPreguntasFrecuentesConst_ColorPreguntaCategoria" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Pregunta</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PreguntasFrecuentesConst_ColorPregunta" id="updPreguntasFrecuentesConst_ColorPregunta" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Respuesta</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="PreguntasFrecuentesConst_ColorRespuesta" id="updPreguntasFrecuentesConst_ColorRespuesta" required="required"   />
                                                        </div>
                                                    </div>   
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <img src="" alt="" id="imgshowMayorque" height="50" />   
                                                            <label style ={{marginLeft:10}} className=" control-label">Imagen Mayor que</label>                                                        
                                                            <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Mayorque" 
                                                                id="updImagenes_Mayorque" required="required" style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                onChange ={(e) => validOnChange(e.target.files,"imgshowMayorque",50,50)}  />
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div>
                                            <div className="contBlock">  
                                                <div className="row p-t-20">
                                                    <label className="col-md-6 control-label bold">Menú -> Cambiar Contraseña ..</label>    
                                                    <div className="separador"></div> 
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="CambiarClaveConst_ColorFondo3" id="updCambiarClaveConst_ColorFondo3" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Barra</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="CambiarClaveConst_ColorHeader3" id="updCambiarClaveConst_ColorHeader3" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Barra</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="CambiarClaveConst_FuenteColorHeader3" id="updCambiarClaveConst_FuenteColorHeader3" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto "Cambio Contraseña .."</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="CambiarClaveConst_ColorTexto" id="updCambiarClaveConst_ColorTexto" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Texto Digitado</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="CambiarClaveConst_ColorFondoInput" id="updCambiarClaveConst_ColorFondoInput" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Borde Texto Digitado</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="CambiarClaveConst_borderColorInputCambiarC" id="updCambiarClaveConst_borderColorInputCambiarC" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Digitado</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="CambiarClaveConst_ColorTextoInput" id="updCambiarClaveConst_ColorTextoInput" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Botón "Aceptar"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="CambiarClaveConst_ColorFondoBotonesModal" id="updCambiarClaveConst_ColorFondoBotonesModal" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Texto Botón "Aceptar"</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="CambiarClaveConst_colorTextoBotonCambiarC" id="updCambiarClaveConst_colorTextoBotonCambiarC" required="required"   />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <img src="" alt="" id="imgshowUsuarioMarket" height="50" />   
                                                            <label style ={{marginLeft:10}} className=" control-label">Imagen Usuario Market</label>                                                        
                                                            <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_UsuarioMarket" 
                                                                id="updImagenes_UsuarioMarket" required="required" style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                onChange ={(e) => validOnChange(e.target.files,"imgshowUsuarioMarket",85,85)}  />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="contBlock">  
                                                <div className="row p-t-20">
                                                    <label className="col-md-6 control-label bold">Menú -> Solicitud de Soporte</label>    
                                                    <div className="separador"></div> 
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className=" control-label">Color Fondo Pantalla</label>                                                        
                                                            <input type="text" className="form-control" placeholder="" name="SolicitudSoporte_FondoModal5" id="updSolicitudSoporte_FondoModal5" required="required"   />
                                                        </div>
                                                    </div>    
                                                    <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Color Fondo Barra</label>                                                        
                                                                <input type="text" className="form-control" placeholder="" name="SolicitudSoporte_ColorHeader10" id="updSolicitudSoporte_ColorHeader10" required="required"   />
                                                            </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Color Texto Barra</label>                                                        
                                                                <input type="text" className="form-control" placeholder="" name="SolicitudSoporte_FuenteColorHeader9" id="updSolicitudSoporte_FuenteColorHeader9" required="required"   />
                                                            </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Color Texto Título "Solicitud de ..."</label>                                                        
                                                                <input type="text" className="form-control" placeholder="" name="SolicitudSoporte_ColorTextoTitulo" id="updSolicitudSoporte_ColorTextoTitulo" required="required"   />
                                                            </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Color Texto "Escríbenos"</label>                                                        
                                                                <input type="text" className="form-control" placeholder="" name="SolicitudSoporte_ColorTextoConsejo" id="updSolicitudSoporte_ColorTextoConsejo" required="required"   />
                                                            </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Color Fondo Comentario</label>                                                        
                                                                <input type="text" className="form-control" placeholder="" name="SolicitudSoporte_FondoAreaText9" id="updSolicitudSoporte_FondoAreaText9" required="required"   />
                                                            </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Color Borde Comentario</label>                                                        
                                                                <input type="text" className="form-control" placeholder="" name="SolicitudSoporte_ColorBordeAreaTexto" id="updSolicitudSoporte_ColorBordeAreaTexto" required="required"   />
                                                            </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Color Texto "Su Comentario"</label>                                                        
                                                                <input type="text" className="form-control" placeholder="" name="SolicitudSoporte_ColorTextoDigSuComentario" id="updSolicitudSoporte_ColorTextoDigSuComentario" required="required"   /> 
                                                            </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Color Fondo Botón "Enviar"</label>                                                        
                                                                <input type="text" className="form-control" placeholder="" name="SolicitudSoporte_ColorFondoBoton" id="updSolicitudSoporte_ColorFondoBoton" required="required"   />
                                                            </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className=" control-label">Color Texto Botón "Enviar"</label>                                                        
                                                                <input type="text" className="form-control" placeholder="" name="SolicitudSoporte_ColorFondoBotonTexto" id="updSolicitudSoporte_ColorFondoBotonTexto" required="required"   />
                                                            </div>
                                                    </div>
                                                </div>    
                                            </div>    
                                        </form>
                                        <div className="form-group">
                                            <div className="col-sm-offset-2 col-sm-10">
                                                <button type="button" className="btn btn-default btnupdate">Actualizar</button>
                                            </div>
                                        </div>
                                        {/* ***************** */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default updateData