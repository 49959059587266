import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from './components/login/Login';
import Forgot from './components/login/Forgot';
import Dashboard from './components/dashboard/Dashboard';
import Status from './components/status/index'
import StatusList from './components/status/list'
import Sectors from './components/sectors/index'
import SectorsList from './components/sectors/list'
import Paymentmethods from './components/Paymentmethods'
import PaymentmethodsList from './components/Paymentmethods/list'
import Typedocument from './components/typedocument'
import TypedocumentList from './components/typedocument/list'
import Typeshipping from './components/typeshipping'
import TypeshippingList from './components/typeshipping/list'
import Typetaxes from './components/typetax'
import TypetaxesList from './components/typetax/list'
import Typemarketplace from './components/typemarketplace'
import TypemarketplaceList from './components/typemarketplace/list'
import Marketlist from './components/marketplace/List'
import Marketcreate from './components/marketplace'
import Cedislist from './components/cedis/List'
import Cediscreate from './components/cedis/index'
import Fabdistlist from './components/fabdist/List'
import Fabdistcreate from './components/fabdist'
import advertising from  './components/advertising'
import advertisinglist from './components/advertising/list'
import user from './components/user/index'
import userlist from './components/user/list'

function App() {
  return (
    <div >
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/forgot" component={Forgot} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/status" component={Status} />     
          <Route path="/statuslist" component={StatusList} />    
          <Route path="/sectors" component={Sectors} />     
          <Route path="/sectorslist" component={SectorsList} />    
          <Route path="/paymentmethods" component={Paymentmethods} />   
          <Route path="/paymentmethodslist" component={PaymentmethodsList} />   
          <Route path="/typedocument" component={Typedocument} />    
          <Route path="/typedocumentlist" component={TypedocumentList} />   
          <Route path="/typeshipping" component={Typeshipping} />   
          <Route path="/typeshippinglist" component={TypeshippingList} />  
          <Route path="/typetaxes" component={Typetaxes} />
          <Route path="/typetaxeslist" component={TypetaxesList} />
          <Route path="/typemarketplace" component={Typemarketplace} />
          <Route path="/typemarketplacelist" component={TypemarketplaceList} />        
          <Route path="/marketlist" component={Marketlist} />        
          <Route path="/marketcreate" component={Marketcreate} />       
          <Route path="/cedislist" component={Cedislist} />        
          <Route path="/cediscreate" component={Cediscreate} />       
          <Route path="/fabdistlist" component={Fabdistlist} />        
          <Route path="/fabdistcreate" component={Fabdistcreate} />  
          <Route path="/advertising" component={advertising} /> 
          <Route path="/advertisinglist" component={advertisinglist} /> 
          <Route path="/usercreate" component={user} /> 
          <Route path="/userlist" component={userlist} />               
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
