import React, {Component} from 'react'
import { endSession } from '../../assets/js/GeneralScript';
import Const from '../utils/defaultConstant'
class inputMarketplaceWhite extends Component {

    constructor(props){
        super(props);
        this.state = { items:[] }
    }

    componentDidMount(){
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "/api/marketplace/white", { // ok
            headers: { 
                'Authorization': token,
            },
        }).then(response => response.json())
        .then((result) => {
            if (endSession(result.success))
                this.setState({ items: result.data })
        })
    }

    render(){
        const { items } = this.state
        
        return  (
            <React.Fragment>                
                <div className="col-md-4">
                    <div className="form-group">
                        <label className=" control-label">Marketplace Marca Blanca B2C</label>
                            <select className="form-control" id="updmarketplace_b2c" name="idMarketplace_b2c" >  
                            <option value="0">Seleccionar</option>    
                            {items.map(item => (
                                <option value={item.id} >{item.name}</option>
                            ))}                                          
                            </select>                                                              
                        </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default inputMarketplaceWhite