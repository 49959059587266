import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import $ from 'jquery';
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import InputMarketplaceWhite from '../atoms/InputMarketplaceWhite'
import InputFabdist from '../atoms/InputFabdist'
import InputStatus from '../atoms/InputStatusCedis'
import InputMarketplaceAso from '../atoms/InputMarketplaceAso'
import InputSectors from '../atoms/InputSectors'
import Const from '../utils/defaultConstant'
import langEs from '../utils/langEs'
import AlertGeneral from '../atoms/AlertGeneral'
import {alertaGeneral,closeAlertGeneral, endSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar');

class Cedis extends Component {

    componentDidMount() {
        var el = document.getElementById('mggAlert');
        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }

        function validURL(str) {
            var pattern = new RegExp('^(https?:\\/\\/)?' +
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                '((\\d{1,3}\\.){3}\\d{1,3}))' +
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
                '(\\?[;&a-z\\d%_.~+=-]*)?' +
                '(\\#[-a-z\\d_]*)?$', 'i');
            return !!pattern.test(str);
        }

        function validarEmail(valor) {
            if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(valor)) {
                return 1
            } else {
                alertaGeneral("La dirección de email es incorrecta!.");
                return 0
            }
        }

        $(document).on('click', '.btnupdate', function () {                
            const registerValid = sessionStorage.getItem("registerValid")   
            if (registerValid == "true") {
                let name = $("#updname").val();
                let lat = $("#updlat").val();
                let lng = $("#updlng").val();
                let radio =parseInt($("#updradio").val());
                let weight = $("#updweight").val()
                let width = $("#updwidth").val()
                let height = $("#updheight").val()
                let nroLicInSite = $("#updnroLicInSite").val()
                let emailAdmin = $("#updemailAdmin").val()
                let emailServiceClient = $("#updemailServiceClient").val()
                let emailvalidoClient = validarEmail(emailServiceClient)
                let emailvalidoAdmin = validarEmail(emailAdmin)
                let description = $("#upddescription").val()
                let address = $("#updaddress").val()
                let slogan = $("#updslogan").val()
                let nameAdmin = $("#updnameAdmin").val()
                let phoneAdmin = $("#updphoneAdmin").val()
                let password = $("#updpassword").val()
                let numbersImages = parseInt($("#updnumbersImages").val())
                let balanceNotifications = $("#updbalanceNotifications").val()
                let order = $("#updorder").val()
                let textTyc = $("#updtextTyc").val()
                let textPd = $("#updtextPd").val()
                let textAutoriza = $("#updtextAutoriza").val()
                let idCedi

                if ($("#updidmarketplaceaso").val() >= 1 || $("#updmarketplace").val() >= 1){
                    if ($("#updimg").val() != ""){                
                        if (password.length >= 5) {
                            if (emailvalidoClient == "1" &&
                                emailvalidoAdmin == "1") {
                                if (lat != "" && lng != "" && radio >= 1 ) {
                                    let social = []
                                    let coordinates = {
                                        'lat': lat,
                                        'lng': lng,
                                        'radio': radio
                                    }
                                    $(".urlsocial").each(function (index) {
                                        if (this.value != "") {
                                            let url = validURL(this.value)
                                            if (url) {
                                                social.push({
                                                    'name': this.id,
                                                    'url': this.value
                                                })
                                            }
                                        }    
                                    })
                                    $("#updsocialRef").val(JSON.stringify(social))
                                    $("#updcoordinates").val(JSON.stringify(coordinates))                                                                    
                                    if (($('#updimg').get(0).files.length != 0) &&
                                        ($('#updImagenes_BackArrow').get(0).files.length != 0) &&
                                        ($('#updImagenes_Facebook').get(0).files.length != 0) &&
                                        ($('#updImagenes_Linkeding').get(0).files.length != 0) &&
                                        ($('#updImagenes_Twitter').get(0).files.length != 0) &&
                                        ($('#updImagenes_Whatsapp').get(0).files.length != 0) &&
                                        ($('#updImagenes_Youtube').get(0).files.length != 0) &&
                                        ($('#updImagenes_Instagram').get(0).files.length != 0) &&
                                        ($('#updImagenes_Buscar').get(0).files.length != 0) &&
                                        ($('#updImagenes_Basket').get(0).files.length != 0) &&
                                        ($('#updImagenes_Lista').get(0).files.length != 0) &&
                                        ($('#updImagenes_Mas').get(0).files.length != 0) &&
                                        ($('#updImagenes_Menos').get(0).files.length != 0) &&
                                        ($('#updImagenes_Condiciones').get(0).files.length != 0) &&
                                        ($('#updImagenes_InformacionLegalCedi').get(0).files.length != 0) &&
                                        ($('#updImagenes_Usuario').get(0).files.length != 0) &&
                                        ($('#updImagenes_CheckActivo').get(0).files.length != 0) &&
                                        ($('#updImagenes_CheckInactivo').get(0).files.length != 0) &&
                                        ($('#updImagenes_Telefono').get(0).files.length != 0) &&
                                        ($('#updImagenes_CloseBlack').get(0).files.length != 0) &&
                                        ($('#updImagenes_Categoria').get(0).files.length != 0) &&
                                        ($('#updImagenes_LogoInternoCedi').get(0).files.length != 0) &&
                                        ($('#updImagenes_SplashScreenInternoCedi').get(0).files.length != 0) &&
                                        ($('#updImagenes_PromoEstrella').get(0).files.length != 0) &&
                                        ($('#updImagenes_Estadisticas').get(0).files.length != 0) &&
                                    
                                        name.length >= 4 &&
                                        description.length >= 5 &&
                                        address.length >= 5 &&
                                        nameAdmin.length >= 5 &&
                                        phoneAdmin.length >= 5 &&
                                        balanceNotifications.length >= 1 &&
                                        textTyc.length >= 40 &&
                                        numbersImages >= 1 &&                                        
                                        textPd.length >= 40 &&
                                        textAutoriza.length >= 40 &&
                                        /* cediHide.length >= 1 && */
                                        weight.length >= 1 &&
                                        width.length >= 1 &&
                                        height.length >= 1 &&
                                        slogan.length >= 4 
                                    ){                                
                                        $('#updtypeDisperse_b2b').val("30") // OK
                                        $('#updtypeDisperse_b2c').val("3000") // OK
                                        $('#updvaluePlatformBaseOrder_b2b').val("0") // OK
                                        $('#updvaluePlatformBaseRef_b2b').val("0") // OK
                                        $('#updpercentageFreeDisperse_b2b').val("0") // OK
                                        $('#updpercentageOrdenMin_b2b').val("0") // OK
                                        $('#updpercentageLimitSalesHigher_b2b').val("0") // OK
                                        $('#updvaluePlatformBaseOrder_b2c').val("0") // OK
                                        $('#updvaluePlatformBaseRef_b2c').val("0") // OK
                                        $('#updidMarketplace_b2c').val("0") 
                                        $('#updidMarketplaceASo_b2c').val("0") 

                                        let token = sessionStorage.getItem('token')
                                        const data = new FormData(document.getElementById('formularioCedi'))
                                        fetch(Const.urlrest + "/api/cedi", { // ok
                                            method: "POST",
                                            headers: {
                                                'Authorization': token,
                                            },                            
                                            body: data
                                        })
                                        .then(response => response.json())
                                        .then(
                                            (result) => {
                                                if (endSession(result.success)) {
                                                    if (result.data != null) {
                                                        idCedi = result.data.id
                                                        alertaGeneral("Registro Ingresado")
                                                        document.getElementById("formularioCedi").reset()
                                                    } else 
                                                        alertaGeneral("Verifica los campos")
                                                }
                                            }
                                        )                                            
                                    } else {
                                        alertaGeneral("Revisa los campos, todos son obligatorios");
                                    }
                                } else {
                                    alertaGeneral("Revisa las coordenadas");
                                }
                            } else {
                                alertaGeneral("Por favor ingresa un email valido");
                            }
                        } else {
                            alertaGeneral("Por favor ingresa una contraseña con más de 5 caracteres");
                        }
                    } else {
                        alertaGeneral("la imagen del cedi es obligatoria");
                    }
                } else {
                    alertaGeneral("Es necesario ingresar si es asociativo o marca blanca");
                }
            } else
                alertaGeneral("La imagen seleccionada no es valida, no se permite la operación de Crear")  
        });
    }

    render(){
        
        function validarImagen(FileList,imgshow,updimg,ancho,alto,peso){
            if (FileList[0] != undefined) {
                var uploadFile = FileList[0];
                    
                if (!window.FileReader) {
                    alertaGeneral('El navegador no soporta la lectura de archivos');
                    return;
                }
                    
                if (!(/\.(png)$/i).test(uploadFile.name)) {
                    alertaGeneral('El archivo a adjuntar no es una imagen');
                }
                else {
                    var img = new Image();
                    img.onload = function () {
                        if (this.width.toFixed(0) < ancho || this.height.toFixed(0) < alto) {
                            alertaGeneral('Las medidas deben ser: ancho * alto de '+ancho+' * '+alto);
                            document.getElementById(updimg).value = "";
                        } else 
                            sessionStorage.setItem("registerValid",true) 
                    };
                    img.src = URL.createObjectURL(uploadFile);
                    document.getElementById(imgshow).src = img.src
                }           
            }      
        }

        function validOnChange(filex, imgshow,ancho,alto,imgInput) {
            if (filex[0] != undefined) {
                let output = document.getElementById(imgshow);
                output.src = URL.createObjectURL(filex[0]);
                output.onload = function() {
                    if ((output.naturalWidth.toFixed(0) < ancho) || (output.naturalHeight.toFixed(0) < alto)) {
                        document.getElementById(imgshow).src = ''
                        $('#'+imgInput).value = ""
                        sessionStorage.setItem("registerValid","false")
                        alertaGeneral("La Imagen seleccionada no cumple con la medida de "+ancho+" * "+alto+" pixels")
                    } else
                        sessionStorage.setItem("registerValid","true")
                    URL.revokeObjectURL(output.src) // free memory
                }
            }
        }

        return (
            <div>
                <Headerdashboard/>
                <Sidebar />
                <AlertGeneral /> 
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-12 align-self-center">
                            <h3 className="text-primary">Cedis</h3> 
                        </div>                        
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-title">
                                        <h4>Crear </h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="horizontal-form">                
                                            <div className="form-horizontal" id="formcreate" >
                                                <form id="formularioCedi">
                                                    <div className="form-group">
                                                        <input type="hidden" className="form-control" placeholder=""  id="updid" required="required" name="id"  max="30" />
                                                        <input type="hidden" name="coordinates"  id="updcoordinates" />
                                                        <input type="hidden" name="socialRef"   id="updsocialRef"  />    
                                                        <input type="hidden" name="typeDisperse_b2b" id="updtypeDisperse_b2b" />
                                                        <input type="hidden" name="typeDisperse_b2c" id="updtypeDisperse_b2c" />
                                                        <input type="hidden" name="valuePlatformBaseOrder_b2b" id="updvaluePlatformBaseOrder_b2b" />
                                                        <input type="hidden" name="valuePlatformBaseRef_b2b" id="updvaluePlatformBaseRef_b2b" />
                                                        <input type="hidden" name="percentageFreeDisperse_b2b" id="updpercentageFreeDisperse_b2b" />
                                                        <input type="hidden" name="percentageOrdenMin_b2b" id="updpercentageOrdenMin_b2b" />
                                                        <input type="hidden" name="percentageLimitSalesHigher_b2b" id="updpercentageLimitSalesHigher_b2b" />
                                                        <input type="hidden" name="valuePlatformBaseOrder_b2c" id="updvaluePlatformBaseOrder_b2c" />
                                                        <input type="hidden" name="valuePlatformBaseRef_b2c" id="updvaluePlatformBaseRef_b2c" />
                                                        <input type="hidden" name="idMarketplace_b2c" id="updidMarketplace_b2c" />
                                                        <input type="hidden" name="idMarketplaceASo_b2c" id="updidMarketplaceASo_b2c" />
                                                        <div className="row p-t-20">
                                                            <InputMarketplaceAso />
                                                            <InputMarketplaceWhite />  
                                                            <InputStatus />     
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Vitrina B2B</label>                                                        
                                                                    <select className="form-control" id="updvitrinaB2bStatus" name="vitrinaB2bStatus">      
                                                                        <option value="1" >Activo</option>   
                                                                        <option value="0" >Inactivo</option>                                                                        
                                                                    </select>   
                                                                </div>
                                                            </div>                                           
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Vitrina B2C</label>                                                        
                                                                    <select className="form-control" id="updvitrinaB2cStatus" name="vitrinaB2cStatus">      
                                                                        <option value="1" >Activo</option>   
                                                                        <option value="0" >Inactivo</option>                                                                        
                                                                    </select>   
                                                                </div>
                                                            </div>                                           

                                                            <InputSectors />
                                                        </div>  
                                                        <div className="row p-t-20">                                                
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">{langEs.ACTIVE_CEDI}</label>                                                        
                                                                    <select className="form-control" id="updactCedi" name="actCedi">      
                                                                        <option value="1" >Activo</option>   
                                                                        <option value="0" >Inactivo</option>                                                                        
                                                                    </select>   
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">{langEs.NAME} </label>
                                                                    <input type="text" className="form-control" placeholder="" name="name" id="updname" required="required"  max="60" />                                                        
                                                                </div>
                                                            </div>
                                                            <InputFabdist />
                                                        </div> 
                                                        <div className="row p-t-20">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">{langEs.ADDRESS} </label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="address" id="updaddress" required="required"   />        
                                                                </div>
                                                            </div>                                                
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Slogan</label>                                                        
                                                                    <input type="text" className="form-control" placeholder="" name="slogan"  id="updslogan" required="required"   />        
                                                                </div>
                                                            </div>
                                                         </div>
                                                         <div className="row p-t-20">   
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className=" control-label">{langEs.ACTIVE_DESCRIP}</label>                                                        
                                                                    {/* <input type="text" className="form-control" placeholder="" name="description"  id="upddescription" required="required"   />                         */}
                                                                    <textarea name="description" id="upddescription"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>                                                                                        
                                                        <div className="row p-t-20">                                            
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Logo</label>       
                                                                    <img src="" id="imgshow" height="50" />                                                    
                                                                    <input type="file" className="form-control" placeholder="" name="img" 
                                                                           id="updimg" required="required" accept="image/png" 
                                                                           style={{ width:174, borderColor: "#FFFFFF", backgroundColor: "#FFFFFF" }}
                                                                           onChange ={ (e) => validarImagen(e.target.files,'imgshow','updimg',375,145,'updimg')} />   
                                                                    <label className="resultado"></label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className=" control-label">On/Off Look</label>                                                        
                                                                    <select className="form-control" id="updonOffLook" name="onOffLook">      
                                                                        <option value="1" >Activo</option>   
                                                                        <option value="0" >Inactivo</option>                                                                        
                                                                    </select>   
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className=" control-label">On/Off ListPrices</label>                                                        
                                                                    <select className="form-control" id="updonOffListPrices" name="onOffListPrices">      
                                                                        <option value="1" >Activo</option>   
                                                                        <option value="0" >Inactivo</option>                                                                        
                                                                    </select>   
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className=" control-label">On/Off Notificaciones</label>                                                        
                                                                    <select className="form-control" id="updonOffNotificaciones" name="onOffNotificaciones">      
                                                                        <option value="1" >Activo</option>   
                                                                        <option value="0" >Inactivo</option>                                                                        
                                                                    </select>   
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className=" control-label">On/Off ImgPublicidad</label>                                                        
                                                                    <select className="form-control" id="updonOffImgPublicidad" name="onOffImgPublicidad">      
                                                                        <option value="1" >Activo</option>   
                                                                        <option value="0" >Inactivo</option>                                                                        
                                                                    </select>   
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="row p-t-20">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Nombre Administrador </label>
                                                                    <input type="text" className="form-control" placeholder="" name="nameAdmin" id="updnameAdmin" required="required"  max="60" />    
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Teléfono Administrador</label>                                                        
                                                                    <input type="number" className="form-control" placeholder="" name="phoneAdmin" id="updphoneAdmin" required="required"   />        
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row p-t-20">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Email Administrador </label>
                                                                    <input type="email" className="form-control" placeholder="" name="emailAdmin"  id="updemailAdmin" required="required"  max="60" />    
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Password</label>
                                                                    <input type="password" className="form-control" placeholder="******"
                                                                        id="updpassword" required="required" name="password" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Número de images</label>
                                                                    <input type="number" className="form-control" name="numbersImages" placeholder=""
                                                                            id="updnumbersImages" required="required"
                                                                            max="1" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="contBlock">
                                                            <div className="row p-t-20">
                                                                <label className="col-md-6 control-label bold">Zona de atención del CEDI</label>
                                                                <div className="separador"></div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label className=" control-label">Latitud</label>
                                                                        <input type="text" min="0" step="0.01" className="form-control control-coo" name="latitud" placeholder="Latitud"  id="updlat" required="required"  max="30" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label className=" control-label">Longitud</label>
                                                                        <input type="text" min="0" step="0.01" className="form-control control-coo" name="longitud" placeholder="Longitud"  id="updlng" required="required"  max="30" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label className=" control-label">Km</label>
                                                                        <input type="number" className="form-control  control-radio" name="radio" placeholder="Radio"  id="updradio" required="required"  max="30" /> 
                                                                    </div>
                                                                </div>                                                
                                                            </div>
                                                        </div>                                            
                                                        <div className="row p-t-20">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Método de Entrega del pedido</label>
                                                                        <select className="form-control" id="updmethodEmail" name="methodEmail">      
                                                                            <option value="1" >Email</option>   
                                                                            <option value="2" >Webservices</option>                                                                        
                                                                        </select>   
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Email servicio al Cliente</label>
                                                                    <input type="email" className="form-control" placeholder=""
                                                                        id="updemailServiceClient" required="required" name="emailServiceClient"
                                                                        max="60" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row p-t-20">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Saldo Notificaciones</label>
                                                                    <input type="number" className="form-control" placeholder=""
                                                                            id="updbalanceNotifications" required="required" name="balanceNotifications"
                                                                            max="3" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Orden de Presentación</label>
                                                                    <input type="text" className="form-control" style={{marginLeft:5, width:50}} placeholder="99" 
                                                                        id="updorder" required="required" name="order"
                                                                        max="2" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row p-t-20">
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Términos y condiciones</label>
                                                                    <textarea name="textTyc" id="updtextTyc"></textarea>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Política de datos </label>
                                                                    <textarea name="textPd" id="updtextPd"></textarea>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Autorización Tratamiento de Datos </label>
                                                                    <textarea name="textAutoriza" id="updtextAutoriza"></textarea>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="contBlock">
                                                        <div className="row p-t-20">
                                                            <div className="col-md-12">
                                                                <label className=" control-label bold">Redes sociales </label>
                                                                <div className="form-group">                                                                    
                                                                    <div className="groupInputSocial">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <div className="form-group"> 
                                                                                    <input type="text" className="form-control urlsocial" placeholder="Url Facebook" name="facebook"  id="facebook"  max="120" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <div className="form-group"> 
                                                                                    <input type="text" className="form-control urlsocial" placeholder="Url Youtube"  name="youtube"  id="youtube"   max="120" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <div className="form-group"> 
                                                                                    <input type="text" className="form-control urlsocial" placeholder="Url Instagram" name="instagram"  id="instagram" max="120" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <div className="form-group"> 
                                                                                    <input type="text" className="form-control urlsocial" placeholder="Url Twitter"  name="twitter"  id="twitter"   max="120" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <div className="form-group"> 
                                                                                    <input type="text" className="form-control urlsocial" placeholder="Url Linkedin"  name ="linkedin" id="linkedin"  max="120" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <div className="form-group"> 
                                                                                    <input type="text" className="form-control urlsocial" placeholder="Url WhatsApp"  name="whatsapp"  id="whatsapp"  max="120" />     
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> 
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                    <div className="contBlock">
                                                        <div className="row p-t-20">
                                                            <label className=" col-md-6 control-label bold">Características de las fotos que puede subir el CEDI</label> 
                                                            <div className="separador"></div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Peso en kilobites</label>                                                                
                                                                    <input type="number" className="form-control" placeholder="" name="weight" id="updweight" required="required"  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Ancho en pixeles</label>                                                                
                                                                    <input type="number" className="form-control" placeholder="" name="width" id="updwidth" required="required"  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Alto pixeles </label>                                                                
                                                                    <input type="number" className="form-control" placeholder="" name="height" id="updheight" required="required"  />
                                                                </div>
                                                            </div>                                                                
                                                        </div>
                                                    </div>

                                                    <div className="contBlock">
                                                        <div className="row p-t-20">
                                                            <label className=" col-md-6 control-label bold">App TAT</label> 
                                                            <div className="separador"></div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Nro de Licencias disponibles</label>                                                                
                                                                    <input type="number" className="form-control" placeholder="" name="nroLicInSite" id="updnroLicInSite" required="required"  />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* *************************+************************************************ */}
                                                    <div className="contBlock">
                                                        <div className="row p-t-20">
                                                            <label className=" col-md-6 control-label bold">Imagenes Precargadas Cedi</label> 
                                                            <div className="separador"></div>
                                                            
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowBackArrow" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">BackArrow</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_BackArrow" 
                                                                           id="updImagenes_BackArrow" required="required" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowBackArrow",75,75,'updImagenes_BackArrow')}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowFacebook" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Facebook</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Facebook" 
                                                                           id="updImagenes_Facebook" required="required" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowFacebook",75,75,'updImagenes_Facebook')}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowLinkeding" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Linkeding</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Linkeding" 
                                                                           id="updImagenes_Linkeding" required="required" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowLinkeding",75,75,'updImagenes_Linkeding')}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowTwitter" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Twitter</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Twitter" 
                                                                           id="updImagenes_Twitter" required="required" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowTwitter",75,75,'updImagenes_Twitter')}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowWhatsapp" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Whatsapp</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Whatsapp" 
                                                                           id="updImagenes_Whatsapp" required="required" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowWhatsapp",75,75,'updImagenes_Whatsapp')}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowYoutube" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Youtube</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Youtube" 
                                                                           id="updImagenes_Youtube" required="required" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowYoutube",75,75,'updImagenes_Youtube')}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowInstagram" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Instagram</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Instagram" 
                                                                           id="updImagenes_Instagram" required="required" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowInstagram",75,75,'updImagenes_Instagram')}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowBuscar" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Buscar</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Buscar" 
                                                                           id="updImagenes_Buscar" required="required" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowBuscar",75,75,'updImagenes_Buscar')}  />
                                                                </div>
                                                            </div>                                                        
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowBasket" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Basket</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Basket" 
                                                                           id="updImagenes_Basket" required="required" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowBasket",48,48,'updImagenes_Basket')}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowLista" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Lista</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Lista" 
                                                                           id="updImagenes_Lista" required="required" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowLista",136,198,'updImagenes_Lista')}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowMas" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Mas</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Mas" 
                                                                           id="updImagenes_Mas" required="required" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowMas",44,44,'updImagenes_Mas')}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">                                                                
                                                                    <img src="" alt="" id="imgshowMenos" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Menos</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Menos" 
                                                                           id="updImagenes_Menos" required="required" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowMenos",44,44,'updImagenes_Menos')}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowCondiciones" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Condiciones</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Condiciones" 
                                                                           id="updImagenes_Condiciones" required="required" max="7" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowCondiciones",45,45,'updImagenes_Condiciones')}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowInformacionLegalCedi" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">InformacionLegalCedi</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_InformacionLegalCedi" 
                                                                           id="updImagenes_InformacionLegalCedi" required="required" max="7" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowInformacionLegalCedi",50,50,'updImagenes_InformacionLegalCedi')}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowUsuario" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Usuario</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Usuario" 
                                                                           id="updImagenes_Usuario" required="required" max="7" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowUsuario",85,85,'updImagenes_Usuario')}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowCheckActivo" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">CheckActivo</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_CheckActivo" 
                                                                           id="updImagenes_CheckActivo" required="required" max="7" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowCheckActivo",30,30,'updImagenes_CheckActivo')}  />
                                                                </div>                                                        
                                                            </div>    

                                                            <div className="col-md-3"> 
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowCheckInactivo" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">CheckInactivo</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_CheckInactivo" 
                                                                           id="updImagenes_CheckInactivo" required="required" max="7" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowCheckInactivo",30,30,'updImagenes_CheckInactivo')}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowTelefono" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Teléfono</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Telefono" 
                                                                           id="updImagenes_Telefono" required="required" max="7" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowTelefono",30,30,'updImagenes_Telefono')}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowCloseBlack" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">CloseBlack</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_CloseBlack" 
                                                                           id="updImagenes_CloseBlack" required="required" max="7" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowCloseBlack",18,18,'updImagenes_CloseBlack')}  />
                                                                </div>          
                                                            </div>
                                                            
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowCategoria" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Categoría</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Categoria" 
                                                                           id="updImagenes_Categoria" required="required" max="7" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowCategoria",52,52,'updImagenes_Categoria')}  />
                                                                </div>          
                                                            </div>                                                        
                                                            <div className="col-md-3">   
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowLogoInternoCedi" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Logo Interno Cedi</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_LogoInternoCedi" 
                                                                           id="updImagenes_LogoInternoCedi" required="required" max="7" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowLogoInternoCedi",375,145,'updImagenes_LogoInternoCedi')}  />
                                                                </div>          
                                                            </div>                                                        
                                                            <div className="col-md-3">   
                                                                <div className="form-group">
                                                                    <img src="" style={{width:174, height:275.5, border: "#F9F9F9", backgroundColor: "#F9F9F9"}} alt="" id="imgshowSplashScreenInternoCedi" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Splash Screen Interno Cedi</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_SplashScreenInternoCedi" 
                                                                           id="updImagenes_SplashScreenInternoCedi" required="required" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowSplashScreenInternoCedi",450,713,'updImagenes_SplashScreenInternoCedi')} />
                                                                </div>          
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowPromoEstrella" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">PromoEstrella</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_PromoEstrella" 
                                                                           id="updImagenes_PromoEstrella" required="required" max="7" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowPromoEstrella",45,45,'updImagenes_PromoEstrella')}  />
                                                                </div>          
                                                            </div>                                                                                                                
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <img src="" alt="" id="imgshowEstadisticas" height="50" />   
                                                                    <label style ={{marginLeft:10}} className=" control-label">Estadísticas</label>                                                        
                                                                    <input type="file" className="form-control" accept="image/png" placeholder="" name="Imagenes_Estadisticas" 
                                                                           id="updImagenes_Estadisticas" required="required" max="7" 
                                                                           style={{ width:174, borderColor: "#F7F7F7", backgroundColor: "#F7F7F7" }}
                                                                           onChange ={(e) => validOnChange(e.target.files,"imgshowEstadisticas",50,50,'updImagenes_Estadisticas')}  />
                                                                </div>
                                                            </div>
                                                        </div>    
                                                    </div>
                                                </form> 
                                                <form id="formularioB2B">                                
                                                        {/* *************** Vitrina B2B ************************* */}                           
                                                        <input type="hidden" name="vpPedidoMinimo" id="updvpPedidoMinimo" value={0} />
                                                        <input type="hidden" name="typeFreight" id="updtypeFreight" value={0} />
                                                        <input type="hidden" name="vpValorFlete" id="updvpValorFlete" value={0} />
                                                        <input type="hidden" name="vpValorPorcentualFlete" id="updvpValorPorcentualFlete" value={0} />
                                                        <input type="hidden" name="vpTipoOferta" id="updvpTipoOferta" value={0} />
                                                        <input type="hidden" name="vpCompraValorFleteCero" id="updvpCompraValorFleteCero" value={0} />
                                                        <input type="hidden" name="vpCediVisibleEn" id="updvpCediVisibleEn" value={0} />
                                                        <input type="hidden" name="vpTipoDespacho" id="updvpTipoDespacho" value={0} />
                                                        <input type="hidden" name="release" id="updrelease" value="" />
                                                </form>
                                                <form id="formularioB2C"> 
                                                        {/* *************** Vitrina B2C ************************* */}                           
                                                        <input type="hidden" name="vpPedidoMinimo_b2c" id="updvpPedidoMinimo_b2c" value={0} />
                                                        <input type="hidden" name="typeFreight_b2c" id="updtypeFreight_b2c" value={0} />
                                                        <input type="hidden" name="vpValorFlete_b2c" id="updvpValorFlete_b2c" value={0} />
                                                        <input type="hidden" name="vpValorPorcentualFlete_b2c" id="updvpValorPorcentualFlete_b2c" value={0} />
                                                        <input type="hidden" name="vpTipoOferta_b2c" id="updvpTipoOferta_b2c" value={0} />
                                                        <input type="hidden" name="vpCompraValorFleteCero_b2c" id="updvpCompraValorFleteCero_b2c" value={0} />
                                                        <input type="hidden" name="vpCediVisibleEn_b2c" id="updvpCediVisibleEn_b2c" value={0} />
                                                        <input type="hidden" name="vpTipoDespacho_b2c" id="updvpTipoDespacho_b2c" value={0} />
                                                        <input type="hidden" name="release_b2c" id="updrelease_b2c" value="" />
                                                </form> 
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-offset-2 col-sm-10">
                                                    <button type="button" className="btn btn-default btnupdate"  id="btnupdate">Ingresar</button>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Cedis;