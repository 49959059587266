import React, {Component} from 'react'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import UpdateData from './updateData'
import Const from '../utils/defaultConstant'
import $ from 'jquery';
import MaterialTable from 'material-table';
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import {alertaGeneral,closeAlertGeneral,alertaConfirm, endSession} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar');
class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    componentDidMount() { 
        var el = document.getElementById('mggAlert');
        var close = document.querySelector(".closeConfirm")
        var btnAlertConfirm = document.querySelector("#btnAlertConfirm")

        function formConfirmDelete() {
            var nid = document.getElementById("btnAlertConfirm").getAttribute("data-nid")
            if (nid >= 1) {
                document.querySelector('#mggAlertConfirm').style.display = 'none'
                formDelete(nid)
            }
        }

        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }
        if (close) {
            close.addEventListener("click", closeAlertGeneral);
        }
        if (btnAlertConfirm) {
            btnAlertConfirm.addEventListener("click", formConfirmDelete);
        }

        function formDelete(id) {
            if (id >= 1) {
                var datos = {
                    id: id
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "/api/marketplace/destroy", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "DELETE",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            if(result.data == "1"){
                                alertaGeneral("Registro  Eliminado")
                                window.location.reload(false)
                            }else{
                                 alertaGeneral("No se puede eliminar")
                            }     
                        }
                    }
                )
            } else {
                alertaGeneral("Datos  incorrectos");
            }
        }
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "/api/marketplace",{
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success)) {
                    if (result.length != 0) {
                        result.data.forEach(function (element){
                            if (element.ActMarketplace == 1) {
                                    element.ActMarketplace = "Activo"
                                    /* element.status = 1 */
                            }else{
                                element.ActMarketplace = "Inactivo"
                                /* element.status = 2 */
                            }
                            if (element.typeBusiness == 1) {
                                element.typeBusiness = "B2B"
                                /* element.status = 1 */
                            }else{
                                element.typeBusiness = "B2C"
                                /* element.status = 2 */
                            }
                    })
                        this.setState({ items: result.data })
                    } else 
                        alertaGeneral("Marketplace inactivo temporalmente")        
                }
            }
        ).catch(error => {alertaGeneral("Problemas de conexión")})    

        function validURL(str) {
            var pattern = new RegExp('^(https?:\\/\\/)?' +
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                '((\\d{1,3}\\.){3}\\d{1,3}))' +
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
                '(\\?[;&a-z\\d%_.~+=-]*)?' +
                '(\\#[-a-z\\d_]*)?$', 'i');
            return !!pattern.test(str);
        }
        
        $(document).off().on('click', '.btnupdate', function () {
            let id = $("#updid").val();
            let name = $("#updname").val();
            let linkAppStore = $("#updlinkAppStore").val();
            let ActMarketplace = $("#updActMarketplace").val()
            let nameClient = $("#updnameClient").val()
            let weight = parseInt($("#updweight").val())
            let width = parseInt($("#updwidth").val())
            let height = parseInt($("#updheight").val())
            let typeDocument = $("#updtypeDocument").val()
            let sectors = $("#updsectors").val()
            let typeMarketplace = $("#updtypeMarketplace").val()
            let numberDocument = $("#updnumberDocument").val()
            let phone = $("#updphone").val()
            let note = $("#updnote").val()
            let address = $("#updaddress").val()
            let versMarketplace = $("#updversMarketplace").val()
            let typeBusiness = $("#updtypeBusiness").val()
            let vitrinaMarketplace = $("#updvitrinaMarketplace").val()
            let onOffLookBase = $("#updonOffLookBase").val()
            let textTyc = $("#updtextTyc").val()
            let textAutoriza = $("#updtextAutoriza").val()
            let privKeyFireBase = $("#updprivKeyFireBase").val()
            let textPd = $("#updtextPd").val()
            let faqs = []
            let social = []
            $(".urlsocial").each(function (index) {
                if (this.value != "") {
                    let url = validURL(this.value)
                    if (url) {
                        social.push({
                            "name": this.id,
                            "url": this.value
                        })
                    }
                }
            });
            $(".itemfaqs").each(function (index) {
                if ($(this).find(".tfaqs").value != "") {
                    let Questions = []
                    let titlefaqs = $(this).find(".tfaqs").val()
                    let groupQuestions = $(this).find(".groupInputFaqs")
                    $(groupQuestions).each(function (index) {
                        if ($(this).find(".qfaqs").val() != "" &&
                            $(this).find(".rfaqs").val() != "") {
                            Questions.push({
                                "pregunta": $(this).find(".qfaqs").val(),
                                "respuesta": $(this).find(".rfaqs").val()
                            })
                        }
                    })
                    if (Questions.length >= 1) {
                        faqs.push({
                            "categoria": titlefaqs,
                            "respuestas": Questions
                        })
                    }
                }
            })
           
            if (nameClient.length >= 4 &&name.length >= 4 && numberDocument.length >= 6 && phone.length >= 7 &&
                note.length >= 5 && address.length >= 4 && 
                versMarketplace.length >= 2 && textTyc.length >= 4 && textPd.length >= 4 &&
                weight >= 1 && width >= 1 && height >= 1) {
                var datos = {
                    id: id,
                    name: name,
                    linkAppStore: linkAppStore,
                    ActMarketplace: ActMarketplace,
                    typeDocument: typeDocument,
                    sectors: sectors,
                    typeMarketplace: typeMarketplace,
                    nameClient: nameClient,
                    numberDocument: numberDocument,
                    phone: phone,
                    note: note,
                    address: address,
                    socialRef: JSON.stringify(social),
                    faqs: JSON.stringify(faqs),
                    versMarketplace: versMarketplace,
                    typeBusiness: typeBusiness,
                    textTyc: textTyc,
                    textAutoriza: textAutoriza,
                    privKeyFireBase: privKeyFireBase,
                    textPd: textPd,
                    weight : weight,
                    width : width,
                    height : height,
                    vitrinaMarketplace : vitrinaMarketplace,
                    onOffLookBase : onOffLookBase,
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "/api/marketplace/update", { // ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "PUT",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            alertaGeneral("Registro  Actualizado")
                            window.scrollTo(0, 0)
                            document.querySelector('.formupdate').style.display = 'none'    
                        }
                    }
                )
                const data = new FormData(document.getElementById('formularioupdate'))            
                fetch(Const.urlrest + "/api/colorsMarketplace/update", { // ok
                    headers: { 
                        /* 'Accept': 'application/json',
                        'Content-Type': 'application/json', */
                        'Authorization': token,
                    },
                    method: "PUT",
                    body: data
                }).then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            alertaGeneral("Registro  Actualizado")
                            window.location.reload(false)    
                        }
                    }
                )
                .catch(error => alertaGeneral("Problemas de conexion") )
            } else {
                alertaGeneral("Revisa los campos, todos son obligatorios")
            }
        })
    }

    render(){

        function formEdit(id) {
            window.scrollTo(0, 0);
            document.querySelector('.formupdate').style.display = 'block'
            let token = sessionStorage.getItem('token')
            fetch(Const.urlrest + "/api/marketplace/read", { // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify({
                    id: id
                })
            })
            .then(response => response.json())
            .then(
                (response) => {
                    if (endSession(response.success)) {
                        $("#updid").val(response.data.id)
                        $("#updname").val(response.data.name)
                        $("#updlinkAppStore").val(response.data.linkAppStore)
                        $("#updnameClient").val(response.data.nameClient)
                        $("#updphone").val(response.data.phone)
                        $("#updweight").val(response.data.weight)
                        $("#updwidth").val(response.data.width)
                        $("#updheight").val(response.data.height)
                        $("#updnote").val(response.data.note)
                        $("#updaddress").val(response.data.address)
                        $("#updsocialRef").val(response.data.socialRef)
                        $("#updversMarketplace").val(response.data.versMarketplace)
                        $("#updtypeBusiness").val(response.data.typeBusiness)
                        $("#updvitrinaMarketplace").val(response.data.vitrinaMarketplace)
                        $("#updonOffLookBase").val(response.data.onOffLookBase)
                        $("#updtextTyc").val(response.data.textTyc)
                        $("#updtextAutoriza").val(response.data.textAutoriza)
                        $("#updprivKeyFireBase").val(response.data.privKeyFireBase)
                        $("#updtextPd").val(response.data.textPd)
                        $("#updnumberDocument").val(response.data.numberDocument)
                        $("#updActMarketplace option[value=" + response.data.ActMarketplace + "] ").attr('selected', 'selected');
                        $("#updtypeMarketplace option[value=" + response.data.typeMarketplace + "] ").attr('selected', 'selected');
                        $("#updtypeDocument option[value=" + response.data.typeDocument + "] ").attr('selected', 'selected');
                        $("#updsectors option[value=" + response.data.sectors + "] ").attr('selected', 'selected');
                        // $("#updonOffLookBase option[value=" + response.data.onOffLookBase + "] ").attr('selected', 'selected');
                        let socialRef = JSON.parse(response.data.socialRef)
                        socialRef.forEach(element => {
                            $("#" + element.name).val(element.url)
                        })
                        
                        let faqs = JSON.parse(response.data.faqs)
                        faqs.forEach(element => {
                            let respuestas = element.respuestas
                            let itemfaqs = ' <div class="itemfaqs">' +
                                '<button class="addCatFaqsmenosppal">-</button>' +
                                '<input type="text" class="form-control tfaqs" placeholder="Nombre categoría"  value="' + element.categoria + '"  id="tpreguntas"  max="120" /> ' +
                                '<a href="#nogo" class="addCatFaqsmas">Agregar Pregunta y respuesta</a><div class="separador"></div>';                
                            respuestas.forEach(resp => {
                                itemfaqs += '<div class="groupInputFaqs">' +
                                    '<button class="addCatFaqsmenos">-</button>' +
                                    '<input type="text" class="form-control qfaqs" value="' + resp.pregunta + '" placeholder="Pregunta"   id="pregunta"  max="120" /> ' +
                                    '<input type="text" class="form-control rfaqs" placeholder="Respuesta"  value="' + resp.respuesta + '"  id="respuesta"  max="255" />' +
                                    '</div>';
                            })
                            itemfaqs += '</div> ';
                            $(".itemfaqs").remove()
                            $(".form-group-faqs").append(itemfaqs)
                        })    
                    }
                }
            )
            let itemfaqs = ' <div class="itemfaqs">' +
                '<button class="addCatFaqsmenosppal">-</button>' +
                '<input type="text" class="form-control tfaqs" placeholder="Nombre categoría"   id="tpreguntas"  max="120" /> ' +
                '<a href="#nogo" class="addCatFaqsmas">Agregar Pregunta y respuesta</a><div class="separador"></div>' +
                '<div class="groupInputFaqs">' +
                '<button class="addCatFaqsmenos">-</button>' +
                '<input type="text" class="form-control qfaqs" placeholder="Pregunta"   id="pregunta"  max="120" /> ' +
                '<input type="text" class="form-control rfaqs" placeholder="Respuesta"   id="respuesta"  max="255" />' +
                '</div>' +
                '</div> ';

            let itemquestion = '<div class="groupInputFaqs">' +
                '<button class="addCatFaqsmenos">-</button>' +
                '<input type="text" class="form-control qfaqs" placeholder="Pregunta"   id="pregunta"  max="120" /> ' +
                '<input type="text" class="form-control rfaqs" placeholder="Respuesta"   id="respuesta"  max="255" />' +
                '</div>';

            $(document).on('click', '.addCatFaqs', function () {
                $(".form-group-faqs").append(itemfaqs)
            });
            $(document).on('click', '.addCatFaqsmenosppal', function () {
                $(this).parent(".itemfaqs").remove()
            });
            $(document).on('click', '.addCatFaqsmas', function () {
                $(this).parent(".itemfaqs").append(itemquestion)
                return false
            });
            $(document).on('click', '.addCatFaqsmenos', function () {
                $(this).parent(".groupInputFaqs").remove()
                return false
            });
            /* **************** */
            
            fetch(Const.urlrest + "/api/colorsMarketplace/read", { // ok
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify({ 
                    idMarketplace: id,
                })
            }).then(response => response.json())
            .then(
                (result) => {
                    if (endSession(result.success)) {
                        document.getElementById("updidMarketplace").value = result.data.idMarketplace  
                        // ****************** TYCCONST ***************  Tabla Marketplace
                        document.getElementById("updTyCConst_FuenteColorHeader10").value = result.data.TyCConst_FuenteColorHeader10  
                        document.getElementById("updTyCConst_ColorFondoPantalla1").value = result.data.TyCConst_ColorFondoPantalla1 
                        document.getElementById("updTyCConst_ColorFondoBarra1").value = result.data.TyCConst_ColorFondoBarra1  
                        document.getElementById("updTyCConst_buttonColor").value = result.data.TyCConst_buttonColor  
                        document.getElementById("updTyCConst_selectedButtonColor").value = result.data.TyCConst_selectedButtonColor  
                        document.getElementById("updTyCConst_textoModalInfoCedi").value = result.data.TyCConst_textoModalInfoCedi  
                        // ****************** SOLICITUDSOPORTE ***************  Tabla Marketplace
                        document.getElementById("updSolicitudSoporte_ColorHeader10").value = result.data.SolicitudSoporte_ColorHeader10  
                        document.getElementById("updSolicitudSoporte_ColorFondoBoton").value = result.data.SolicitudSoporte_ColorFondoBoton  
                        document.getElementById("updSolicitudSoporte_FuenteColorHeader9").value = result.data.SolicitudSoporte_FuenteColorHeader9  
                        document.getElementById("updSolicitudSoporte_FondoAreaText9").value = result.data.SolicitudSoporte_FondoAreaText9  
                        document.getElementById("updSolicitudSoporte_FondoModal5").value = result.data.SolicitudSoporte_FondoModal5  
                        document.getElementById("updSolicitudSoporte_ColorTextoTitulo").value = result.data.SolicitudSoporte_ColorTextoTitulo  
                        document.getElementById("updSolicitudSoporte_ColorTextoConsejo").value = result.data.SolicitudSoporte_ColorTextoConsejo  
                        document.getElementById("updSolicitudSoporte_ColorBordeAreaTexto").value = result.data.SolicitudSoporte_ColorBordeAreaTexto  
                        document.getElementById("updSolicitudSoporte_ColorFondoBotonTexto").value = result.data.SolicitudSoporte_ColorFondoBotonTexto  
                        document.getElementById("updSolicitudSoporte_ColorTextoDigSuComentario").value = result.data.SolicitudSoporte_ColorTextoDigSuComentario  
                        // *************************** REGISTROCONST *********  Tabla Marketplace
                        document.getElementById("updRegistroConst_ColorHeader9").value = result.data.RegistroConst_ColorHeader9  
                        document.getElementById("updRegistroConst_ColorFondoBotonRegistro").value = result.data.RegistroConst_ColorFondoBotonRegistro  
                        document.getElementById("updRegistroConst_FuenteColorHeader8").value = result.data.RegistroConst_FuenteColorHeader8  
                        document.getElementById("updRegistroConst_ColorFondo9").value = result.data.RegistroConst_ColorFondo9  
                        document.getElementById("updRegistroConst_ColorTextoInput2").value = result.data.RegistroConst_ColorTextoInput2  
                        document.getElementById("updRegistroConst_ColorTextoCondicionesClick2").value = result.data.RegistroConst_ColorTextoCondicionesClick2  
                        document.getElementById("updRegistroConst_ColorTextoBotonRegistro").value = result.data.RegistroConst_ColorTextoBotonRegistro  
                        document.getElementById("updRegistroConst_ColorLineaInputs").value = result.data.RegistroConst_ColorLineaInputs  
                        document.getElementById("updRegistroConst_ColorTextoCondiciones2").value = result.data.RegistroConst_ColorTextoCondiciones2  
                        document.getElementById("updRegistroConst_ColorPlaceHolder2").value = result.data.RegistroConst_ColorPlaceHolder2  
                        // *************************** PREGUNTASFRECUENTESCONST *********  Tabla Marketplace
                        document.getElementById("updPreguntasFrecuentesConst_ColorHeader8").value = result.data.PreguntasFrecuentesConst_ColorHeader8  
                        document.getElementById("updPreguntasFrecuentesConst_FuenteColorHeader7").value = result.data.PreguntasFrecuentesConst_FuenteColorHeader7  
                        document.getElementById("updPreguntasFrecuentesConst_ColorPreguntaCategoria").value = result.data.PreguntasFrecuentesConst_ColorPreguntaCategoria  
                        document.getElementById("updPreguntasFrecuentesConst_ColorPregunta").value = result.data.PreguntasFrecuentesConst_ColorPregunta  
                        document.getElementById("updPreguntasFrecuentesConst_ColorRespuesta").value = result.data.PreguntasFrecuentesConst_ColorRespuesta  
                        document.getElementById("updPreguntasFrecuentesConst_ColorFondoPantalla20").value = result.data.PreguntasFrecuentesConst_ColorFondoPantalla20
                        // ********+****************** OLVIDECLAVECONST *****************  Tabla Marketplace
                        document.getElementById("updOlvideClaveConst_ColorHeader7").value = result.data.OlvideClaveConst_ColorHeader7  
                        document.getElementById("updOlvideClaveConst_ColorBotonEnviar2").value = result.data.OlvideClaveConst_ColorBotonEnviar2  
                        document.getElementById("updOlvideClaveConst_FuenteColorHeader6").value = result.data.OlvideClaveConst_FuenteColorHeader6  
                        document.getElementById("updOlvideClaveConst_ColorFondo8").value = result.data.OlvideClaveConst_ColorFondo8  
                        document.getElementById("updOlvideClaveConst_ColorTexto1").value = result.data.OlvideClaveConst_ColorTexto1 
                        document.getElementById("updOlvideClaveConst_ColorTextoBotonEnviar").value = result.data.OlvideClaveConst_ColorTextoBotonEnviar 
                        document.getElementById("updOlvideClaveConst_ColorBordeInput").value = result.data.OlvideClaveConst_ColorBordeInput 
                        document.getElementById("updOlvideClaveConst_ColorTextoInput1").value = result.data.OlvideClaveConst_ColorTextoInput1 
                        // ************************** MENUCONST ************************  Tabla Marketplace
                        document.getElementById("updMenuConst_ModalOpcionesColorFondo").value = result.data.MenuConst_ModalOpcionesColorFondo 
                        document.getElementById("updMenuConst_colorFondoBotonCerrar").value = result.data.MenuConst_colorFondoBotonCerrar  
                        document.getElementById("updMenuConst_ModalOpcionesTextoColor").value = result.data.MenuConst_ModalOpcionesTextoColor  
                        document.getElementById("updMenuConst_ColorFondoModal3").value = result.data.MenuConst_ColorFondoModal3  
                        document.getElementById("updMenuConst_LineaSeparadoraMenuColor").value = result.data.MenuConst_LineaSeparadoraMenuColor  
                        // ************************** DIRECTORIOCONST ***************************  Tabla Marketplace
                        document.getElementById("updDirectorioConst_ColorFondo5").value = result.data.DirectorioConst_ColorFondo5  
                        document.getElementById("updDirectorioConst_TextoMisProveedores1").value = result.data.DirectorioConst_TextoMisProveedores1  
                        document.getElementById("updDirectorioConst_CardColorBorde1").value = result.data.DirectorioConst_CardColorBorde1  
                        document.getElementById("updDirectorioConst_CardTexto1").value = result.data.DirectorioConst_CardTexto1  
                        document.getElementById("updDirectorioConst_ColorTextoProveedores1").value = result.data.DirectorioConst_ColorTextoProveedores1  
                        document.getElementById("updDirectorioConst_ColorFondoContenedor1").value = result.data.DirectorioConst_ColorFondoContenedor1  
                        // ************************** HOMECONST *********************  Tabla Marketplace
                        document.getElementById("updHomeConst_ColorFondo2").value = result.data.HomeConst_ColorFondo2 
                        document.getElementById("updHomeConst_CardColorFondo2").value = result.data.HomeConst_CardColorFondo2 
                        document.getElementById("updHomeConst_TextoMisProveedores2").value = result.data.HomeConst_TextoMisProveedores2 
                        document.getElementById("updHomeConst_CardColorBorde2").value = result.data.HomeConst_CardColorBorde2    
                        document.getElementById("updHomeConst_CardTexto2").value = result.data.HomeConst_CardTexto2    
                        // ************************** PRINCIPALCONST *********************  Tabla Marketplace
                        document.getElementById("updPrincipalConst_ColorBotonNuevoUsu").value = result.data.PrincipalConst_ColorBotonNuevoUsu 
                        document.getElementById("updPrincipalConst_ColorFondoInputs").value = result.data.PrincipalConst_ColorFondoInputs 
                        document.getElementById("updPrincipalConst_ColorTextBotonNuevoUsu").value = result.data.PrincipalConst_ColorTextBotonNuevoUsu 
                        document.getElementById("updPrincipalConst_ColorFondoPantalla").value = result.data.PrincipalConst_ColorFondoPantalla 
                        document.getElementById("updPrincipalConst_ColorTextOlvideClave").value = result.data.PrincipalConst_ColorTextOlvideClave 
                        document.getElementById("updPrincipalConst_ColorTextInputBorde").value = result.data.PrincipalConst_ColorTextInputBorde 
                        document.getElementById("updPrincipalConst_ColorBotonEntrar").value = result.data.PrincipalConst_ColorBotonEntrar 
                        document.getElementById("updPrincipalConst_ColorTextBotonEntrar").value = result.data.PrincipalConst_ColorTextBotonEntrar 

                        document.getElementById("updPrincipalConst_ColorBotonUbicacion").value = result.data.PrincipalConst_ColorBotonUbicacion 
                        document.getElementById("updPrincipalConst_ColorTextBotonUbicacion").value = result.data.PrincipalConst_ColorTextBotonUbicacion 

                        document.getElementById("updPrincipalConst_ColorTextoInputs").value = result.data.PrincipalConst_ColorTextoInputs  
                        document.getElementById("updPrincipalConst_ColorVersion").value = result.data.PrincipalConst_ColorVersion  
                        // ************************* CAMBIARCLAVECONST ***************************  Tabla Marketplace
                        document.getElementById("updCambiarClaveConst_ColorHeader3").value = result.data.CambiarClaveConst_ColorHeader3 
                        document.getElementById("updCambiarClaveConst_ColorFondoBotonesModal").value = result.data.CambiarClaveConst_ColorFondoBotonesModal 
                        document.getElementById("updCambiarClaveConst_FuenteColorHeader3").value = result.data.CambiarClaveConst_FuenteColorHeader3  
                        document.getElementById("updCambiarClaveConst_ColorFondoInput").value = result.data.CambiarClaveConst_ColorFondoInput  
                        document.getElementById("updCambiarClaveConst_ColorFondo3").value = result.data.CambiarClaveConst_ColorFondo3  
                        document.getElementById("updCambiarClaveConst_borderColorInputCambiarC").value = result.data.CambiarClaveConst_borderColorInputCambiarC  
                        document.getElementById("updCambiarClaveConst_ColorTextoInput").value = result.data.CambiarClaveConst_ColorTextoInput  
                        document.getElementById("updCambiarClaveConst_colorTextoBotonCambiarC").value = result.data.CambiarClaveConst_colorTextoBotonCambiarC 
                        document.getElementById("updCambiarClaveConst_ColorTexto").value = result.data.CambiarClaveConst_ColorTexto  
                        // **************************** COMUNICACIONCEDISCONST ********************  Tabla Marketplace
                        document.getElementById("updComunicacionCedisConst_colorBordeTextoCedi1").value = result.data.ComunicacionCedisConst_colorBordeTextoCedi1  
                        document.getElementById("updComunicacionCedisConst_ColorHeader4").value = result.data.ComunicacionCedisConst_ColorHeader4 
                        document.getElementById("updComunicacionCedisConst_ColorFondoBotonesModal1").value = result.data.ComunicacionCedisConst_ColorFondoBotonesModal1  
                        document.getElementById("updComunicacionCedisConst_FuenteColorHeader4").value = result.data.ComunicacionCedisConst_FuenteColorHeader4 
                        document.getElementById("updComunicacionCedisConst_modalContentCedi2").value = result.data.ComunicacionCedisConst_modalContentCedi2  
                        document.getElementById("updComunicacionCedisConst_ColorPlaceholder1").value = result.data.ComunicacionCedisConst_ColorPlaceholder1  
                        document.getElementById("updComunicacionCedisConst_colorTextoAdvertenciaCedi1").value = result.data.ComunicacionCedisConst_colorTextoAdvertenciaCedi1  
                        document.getElementById("updComunicacionCedisConst_ColorTextoBotonesModal1").value = result.data.ComunicacionCedisConst_ColorTextoBotonesModal1  
                        document.getElementById("updComunicacionCedisConst_colorTextoCedi1").value = result.data.ComunicacionCedisConst_colorTextoCedi1  
                        document.getElementById("updComunicacionCedisConst_ColorTextoCondiciones1").value = result.data.ComunicacionCedisConst_ColorTextoCondiciones1  
                        document.getElementById("updComunicacionCedisConst_ColorTextoCondicionesClick1").value = result.data.ComunicacionCedisConst_ColorTextoCondicionesClick1  
                        document.getElementById("updComunicacionCedisConst_CediColorFondo2").value = result.data.ComunicacionCedisConst_CediColorFondo2
                        // ****************************  IMAGENES *********************************
                        document.getElementById("imgshowImagenSuperior").src = result.data.Imagenes_ImagenSuperior  
                        document.getElementById("imgshowLogoTienda").src = result.data.Imagenes_LogoTienda 
                        document.getElementById("imgshowUsuarioMarket").src = result.data.Imagenes_UsuarioMarket
                        document.getElementById("imgshowUser").src = result.data.Imagenes_User
                        document.getElementById("imgshowOjo").src = result.data.Imagenes_Ojo
                        document.getElementById("imgshowMayorque").src = result.data.Imagenes_Mayorque
                        document.getElementById("imgshowInstagramMarket").src = result.data.Imagenes_InstagramMarket
                        document.getElementById("imgshowFacebookMarket").src = result.data.Imagenes_FacebookMarket
                        document.getElementById("imgshowLinkedingMarket").src = result.data.Imagenes_LinkedingMarket
                        document.getElementById("imgshowTwitterMarket").src = result.data.Imagenes_TwitterMarket
                        document.getElementById("imgshowWhatsappMarket").src = result.data.Imagenes_WhatsappMarket
                        document.getElementById("imgshowYoutubeMarket").src = result.data.Imagenes_YoutubeMarket
                        document.getElementById("imgshowBackArrowMarket").src = result.data.Imagenes_BackArrowMarket
                        document.getElementById("imgshowHamburguesa").src = result.data.Imagenes_Hamburguesa
                        document.getElementById("imgshowCloseBlackMarket").src = result.data.Imagenes_CloseBlackMarket    
                    }
                },
            )
            .catch(error => alertaGeneral("Problemas de conexion ..."+error) )
        }

        var permisos = []
        let access = JSON.parse(sessionStorage.getItem('access'))
        access.forEach(function (element) {
            if (element == 20) {
                permisos.push({
                    icon: 'edit',
                    iconProps: {
                        style: {
                            color: "#00569b"
                        }
                    },
                    tooltip: 'Edit',
                    onClick: (event, rowData) => formEdit(rowData.id)
                })
            }
            if (element == 21) {
                permisos.push({
                    icon: 'delete',
                    iconProps: {
                        style: {
                            color: "#ff5722"
                        }
                    },
                    tooltip: 'Delete User',
                    onClick: (event, rowData) => alertaConfirm(rowData.id)
                })
            }
        })

        const { items } = this.state;
    
        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <AlertConfirmation />
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-12 align-self-center">
                            <h3 className="text-primary">MarketPlace</h3> 
                        </div>            
                    </div>
                    <UpdateData />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">                
                                        <div className="table-responsive m-t-40">                                            
                                            <MaterialTable
                                                title="Datos"
                                                columns={[
                                                    { title: 'Id', field: 'id' },
                                                    { title: 'Tipo Neg', field: 'typeBusiness'},
                                                    { title: 'Tipo Marketplace', field: 'b2bTypeMarketplace.name' },
                                                    { title: 'Estado', field: 'ActMarketplace' },
                                                    { title: 'Nombre', field: 'name' },
                                                ]}
                                                data = {
                                                    items
                                                }
                                                options={{
                                                    //exportButton: true,
                                                    headerStyle: {
                                                        backgroundColor: '#251972',
                                                        color: '#FFF'
                                                    },
                                                    actionsColumnIndex: -1,
                                                    filtering: true,
                                                    search: false
                                                }}
                                                actions={permisos}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Category;