import React, {Component} from 'react'
import { endSession } from '../../assets/js/GeneralScript';
import Const from '../utils/defaultConstant'
class inputFabdist extends Component {
    constructor(props){
        super(props);
        this.state = {
            items:[]
        }
    }
    componentDidMount(){
        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "/api/fabdist", {
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success)) {
                    let datos = result.data
                    let data = [] 
                    datos.forEach(element => {
                       if(element.status == "1"){
                        data.push(element)
                       }
                    });
                    this.setState({ items: data })
                }
            }
        )
    }
    
    render() {

        const {  items } = this.state;
        
        return  (
            <React.Fragment>                
                <div className="col-md-4">
                    <div className="form-group">
                        <label className=" control-label">Fabricante/Distribuidor/Mayorista </label>
                            <select  className="form-control" id="updidFabDist" name="idFabDist">      
                            {items.map(item => (
                                <option value={item.id}>{item.name}</option>
                            ))}                                          
                            </select>                                                              
                        </div>
                </div>
            </React.Fragment>          
        )
    }
}

export default inputFabdist