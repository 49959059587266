import React, {Component} from 'react'
import $ from 'jquery';
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import InputMarketplaceAso from '../atoms/InputMarketplaceAso'
import Const from '../utils/defaultConstant'
import AlertGeneral from '../atoms/AlertGeneral'
import AlertConfirmation from '../atoms/AlertConfirm'
import {endSession,alertaGeneral,closeAlertGeneral,alertaConfirm} from '../../assets/js/GeneralScript'
import MaterialTable from 'material-table';

document.body.classList.add('fix-sidebar');

class advertisingmarket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    componentDidMount() {    
        var el = document.getElementById('mggAlert');
        var close = document.querySelector(".closeConfirm")
        var btnAlertConfirm = document.querySelector("#btnAlertConfirm")

        function formConfirmDelete() {
            var nid = document.getElementById("btnAlertConfirm").getAttribute("data-nid")  
            var oldImg1 = document.getElementById("btnAlertConfirm").getAttribute("data-nimg1")                  
            if ( nid>=1){
                document.querySelector('#mggAlertConfirm').style.display = 'none'
                formDelete(nid,oldImg1)
            }
        }
        
        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }
        if (close) {
            close.addEventListener("click", closeAlertGeneral);
        }
        if (btnAlertConfirm) {
            btnAlertConfirm.addEventListener("click", formConfirmDelete);
        }
    
        function formDelete(id,oldImg1) {
            const inicioImg = oldImg1.lastIndexOf('/') + 1
            const finImg = oldImg1.length
            const cadenaExtraida = oldImg1.substring(inicioImg, finImg);
            if (id >= 1) {
                var datos = {
                    id: id,
                    oldImg1: cadenaExtraida
                }
                let token = sessionStorage.getItem('token')
                fetch(Const.urlrest + "/api/advertisingmarket/destroy", { //ok
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    method: "DELETE",
                    body: JSON.stringify(datos)
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            if (result.data == "1") {
                                alertaGeneral("Registro  Eliminado");
                                window.location.reload(false);
                            } else {
                                alertaGeneral("No se puede eliminar"); 
                            }     
                        }
                    },
                )
            } else {
                alertaGeneral("Datos  incorrectos");
            }
        }

        let token = sessionStorage.getItem('token')
        fetch(Const.urlrest + "/api/advertisingmarket", // ok
        {
            method: 'GET', 
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
        })
        .then(response => response.json())
        .then(
            (result) => {
                if (endSession(result.success)) 
                    this.setState({ items: result.data })
            },
        )

        function updateForm() {
            let schedule = document.querySelector("#updschedule").value
            let description = document.querySelector("#upddescription").value
            let idMarketplace = document.querySelector("#formupdate #updidmarketplaceaso").value
            
            if (description.length >= 5 && schedule.length >= 4 && idMarketplace >= 1) {
                let token = sessionStorage.getItem('token')
                const data = new FormData(document.getElementById('formularioupdate'))
                fetch(Const.urlrest + "/api/advertisingmarket/update", {
                    method: "PUT",
                    headers: { 'Authorization': token },                
                    body: data
                })
                .then(response => response.json())
                .then(
                    (result) => {
                        if (endSession(result.success)) {
                            alertaGeneral("Registro  Actualizado");
                            document.querySelector('.formupdate').style.display = 'none'
                            document.getElementById("formularioupdate").reset();
                            window.location.reload(false);    
                        }
                    }
                )
            } else {
                alertaGeneral("Revisa los campos, todos son obligatorios");
            }
        }
        
        var elupd = document.getElementById('btnupdate');
        if (elupd) {
            elupd.addEventListener("click", updateForm);
        }  

    }

    render() {  
        
        function formEdit(id) {
            window.scrollTo(0, 0);
            let token = sessionStorage.getItem('token')
            document.querySelector('.formupdate').style.display = 'block'
            fetch(Const.urlrest + "/api/advertisingmarket/read", { //ok
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                method: "POST",
                body: JSON.stringify({
                    id: id
                })
            })
            .then(response => response.json())
            .then(
                (result) => {
                    if (endSession(result.success)) {
                        document.getElementById("updid").value = result.data.id
                        document.getElementById("updlink").value = result.data.link
                        let day   = result.data.schedule.substr(8,2)
                        let month = result.data.schedule.substr(5,2)
                        let year  = result.data.schedule.substr(0,4)
                        let fecha = year+"-"+month+"-"+day
                        document.getElementById("updschedule").value = fecha
                        document.getElementById("imgshow").src = result.data.img
                        document.getElementById("upddescription").value = result.data.description
                        document.getElementById("updimgDescription").value = result.data.imgDescription
                        $("#updidmarketplaceaso option[value=" + result.data.idMarketplaceASo + "] ").attr('selected', 'selected');    
                        let cadena = result.data.img
                        const inicioImg = cadena.lastIndexOf('/') + 1
                        const finImg = cadena.length
                        const cadenaExtraida = cadena.substring(inicioImg, finImg);
                        document.getElementById("updOldImg").value = cadenaExtraida
                    }
                },
            )
        }

        var permisos = []
        let access = JSON.parse(sessionStorage.getItem('access'))
        access.forEach(function (element) {
            if (element == 23) {
                permisos.push({
                    icon: 'edit',
                    iconProps: {
                        style: {
                            color: "#00569b"
                        }
                    },
                    tooltip: 'Edit',
                    onClick: (event, rowData) => formEdit(rowData.id)
                })
            }
            if (element == 24) {
                permisos.push({
                    icon: 'delete',
                    iconProps: {
                        style: {
                            color: "#ff5722"
                        }
                    },
                    tooltip: 'Delete User',
                    onClick: (event, rowData) => alertaConfirm(rowData.id, rowData.img)
                })
            }
        })

        const { items } = this.state; 

        function validarImagen(FileList,imgOld){
            var uploadFile = FileList[0];
                
            if (!window.FileReader) {
                alertaGeneral('El navegador no soporta la lectura de archivos');
                return;
            }
                
            if (!(/\.(png)$/i).test(uploadFile.name)) {
                alertaGeneral('El archivo a adjuntar no es una imagen')
            }
            else {
                var img = new Image();
                img.onload = function () {
                    if (this.width.toFixed(0) != 500 || this.height.toFixed(0) != 250) {
                        alertaGeneral('Las medidas deben ser: 498 * 120 pixels')
                        $("#updimg").val(document.getElementById("imgshow").value)
                        document.getElementById("imgshow").src = imgOld
                    }
                    else if (uploadFile.size > 30000)
                    {
                        alertaGeneral('El peso de la imagen no puede exceder los 200kb')
                    }          
                };
                img.src = URL.createObjectURL(uploadFile);
                document.getElementById("imgshow").src = img.src
            } 
        }

        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                <AlertConfirmation />
                    <div className="page-wrapper">
                        <div className="row page-titles">
                            <div className="col-md-12 align-self-center">
                                <h3 className="text-primary">Publicidad Asociativos</h3> </div>
                            </div>
                        <div className="container-fluid formupdate" id="formupdate">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-title">
                                            <h4>Actualizar </h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="horizontal-form">
                                                <div className="form-horizontal" id="formcreate" >
                                                    <form enctype="multipart" id="formularioupdate">
                                                    <input type="hidden" className="form-control" placeholder="" name="id" id="updid" required="required"   />
                                                    <input type="hidden" className="form-control" placeholder=""  id="updOldImg" required="required" name="oldImg"  max="30" />
                                                        <div className="row p-t-20">
                                                            <InputMarketplaceAso  />
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Link </label>                                                                
                                                                    <input type="text" className="form-control" placeholder="" name="link" id="updlink" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Fecha caducidad </label>                                                                
                                                                    <input type='date' className="form-control" name="schedule" id="updschedule"  required="required" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row p-t-20">                                                                
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Imagen   </label>    
                                                                    <img src="" id="imgshow" height="50" />        
                                                                    <input type="file" className="form-control"  placeholder="" name="img" id="updimg" required="required" accept="image/png" style={{ width:174, borderColor: "#FFFFFF", backgroundColor: "#FFFFFF" }} onChange ={(e) => validarImagen(e.target.files,document.getElementById("imgshow").src )}  />
                                                                </div>
                                                            </div> 
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className=" control-label">Descripción Imagen </label>                                                                
                                                                    <input type="text" className="form-control" max="60" placeholder="" name="imgDescription" id="updimgDescription" required="required"  />                                                             
                                                                    <label class="msglabel">Mínimo 60 caracteres</label>
                                                                </div>
                                                            </div>  
                                                        </div>
                                                    <div className="row p-t-20">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label className=" control-label">Descripción Campaña  </label>   
                                                                <textarea className="form-control" placeholder="" name="description" id="upddescription" ></textarea>                                                             

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="col-sm-offset-2 col-sm-10">
                                                            <button type="button" className="btn btn-default btnupdate" id="btnupdate">Actualizar</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">   
                                        <div className="table-responsive m-t-40">                                                                                                 
                                            <MaterialTable
                                                title="Datos"
                                                columns={[
                                                    { title: 'id', field: 'id' },                                                        
                                                    { title: 'Fecha caducidad', field: 'schedule' },
                                                    { title: 'Descripción', field: 'description' }
                                                ]}
                                                data = {
                                                    items
                                                }
                                                options={{
                                                    headerStyle: {
                                                        backgroundColor: '#251972',
                                                        color: '#FFF'
                                                    },
                                                    actionsColumnIndex: -1,
                                                    filtering: true,
                                                    search: false
                                                }}
                                                actions={permisos}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>          
                    </div>                    
                </div>
            </div>
        )
    }
}

export default advertisingmarket;