export function alertaGeneral(msg){
    document.querySelector('.msglabel').innerText=msg
    document.querySelector('.mggAlert').style.display = 'block'
    setTimeout(function () {
         document.querySelector('.mggAlert').style.display = 'none'
    }, 4000);
}
export function closeAlertGeneral() {  
    var mggAlertConfirm = document.querySelector('#mggAlertConfirm')
    if (mggAlertConfirm){
        mggAlertConfirm.style.display = 'none'
    }

    document.querySelector('.mggAlert').style.display = 'none'
     var b = document.querySelector("#btnAlertConfirm")
     if (b){
           b.setAttribute("data-nid", "")            
     }
     var c = document.querySelector("#btnAlertConfirm")
     if (c){
           c.setAttribute("data-nimg1", "")            
     }
}
export function alertaConfirm(nid,nimg1) {
    document.querySelector('.mggAlertConfirm').style.display = 'block'
    var b = document.querySelector("#btnAlertConfirm")
    b.setAttribute("data-nid", nid)
    var c = document.querySelector("#btnAlertConfirm")
    c.setAttribute("data-nimg1", nimg1)
    setTimeout(function () {
        document.querySelector('.mggAlert').style.display = 'none'
    }, 3000);
}

export function endSession(statusToken) {
    if (!statusToken) {
        alert("Tiempo de sesión a finalizado")
        sessionStorage.removeItem('name');
        sessionStorage.removeItem('id');
        sessionStorage.removeItem('email');
        window.location.href = "/";    
    } else
        return true
}