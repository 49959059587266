import React, {Component} from 'react'
class Logologin extends Component{
    render(){
        return  (
            <React.Fragment>
                <img style={{ width:480*.35, height:170*.35, marginTop:4 }} src={ require("../../assets/images/logoClanApps.png")} alt="Admin"  className="logologin block" />
            </React.Fragment>          
        )
    }
}

export default Logologin
