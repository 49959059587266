import React, {Component} from 'react'
import InputMarketplaceAso from '../atoms/InputMarketplaceAso'
import '../../assets/css/Admin.css'
import Headerdashboard from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import Const from '../utils/defaultConstant'
import AlertGeneral from '../atoms/AlertGeneral'
import {endSession, alertaGeneral,closeAlertGeneral} from '../../assets/js/GeneralScript'

document.body.classList.add('fix-sidebar');

class advertisingmarket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    componentDidMount() {    

        const fecha = new Date().toISOString().split('T')[0] 
        let day   = fecha.substr(8,2)
        let month = fecha.substr(5,2)
        let year  = fecha.substr(0,4)
        let fechaHoy = year+"-"+month+"-"+day
        document.getElementById("updschedule").value = fechaHoy
        
        var el = document.getElementById('mggAlert');
        if (el) {
            el.addEventListener("click", closeAlertGeneral);
        }

        async function addForm () {

            let schedule = document.querySelector("#updschedule").value
            let img = document.querySelector("#updimg").value
            let idMarketplace = document.querySelector("#updidmarketplaceaso").value
            let imgDescription = document.querySelector("#updimgDescription").value
            let description = document.querySelector("#upddescription").value
            if(img !="") {
                if ((schedule.length >= 4)  && (idMarketplace >= 1) && (imgDescription != "") &&
                    (description != "")) {
                    let token = sessionStorage.getItem('token')
                    const data = new FormData(document.getElementById('formulario'))
                    await fetch(Const.urlrest + "/api/advertisingmarket", { //ok
                        method: "POST",
                        headers: { 
                            'Authorization': token,
                        },                
                        body: data
                    })
                    .then(response => response.json())
                    .then(
                        (result) => {  
                            if (endSession(result.success)) {
                                if (result.data != null) {                                 
                                    alertaGeneral("Registro Ingresado");
                                    document.getElementById("formulario").reset();
                                } else {
                                    alertaGeneral("Verifica los Campos");
                                }        
                            }
                        },
                    )
                } else {
                    alertaGeneral("Revisa los campos, todos son obligatorios");
                }
            } else {
                alertaGeneral("EL campo imagen es obligatorio");
            }
        }
        
        var elcre = document.getElementById('btncreate');
        if (elcre) {
            elcre.addEventListener("click", addForm);
        }

    }

    render() {    

        function validarImagen(FileList){
            var uploadFile = FileList[0];
                
            if (!window.FileReader) {
                alertaGeneral('El navegador no soporta la lectura de archivos');
                return;
            }
                
            if (!(/\.(png)$/i).test(uploadFile.name)) {
                alertaGeneral('El archivo a adjuntar no es una imagen');
            }
            else {
                var img = new Image();
                img.onload = function () {
                    if (this.width.toFixed(0) < 500 || this.height.toFixed(0) < 250) {
                        alertaGeneral('Las medidas deben ser mínimo promedio de ancho: 500 y alto:  250 pixel');
                        document.getElementById("updimg").value = "";
                    }        
                };
                img.src = URL.createObjectURL(uploadFile);
                document.getElementById("imgshow").src = img.src

            }                 
        }

        return (          
            <div>
                <Headerdashboard/>
                <Sidebar />  
                <AlertGeneral /> 
                    <div className="page-wrapper">
                        <div className="row page-titles">
                            <div className="col-md-12 align-self-center">
                                <h3 className="text-primary">Publicidad Asociativos</h3> 
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-title">
                                            <h4>Crear </h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="horizontal-form">
                                                <div className="form-horizontal" id="formcreate" >
                                                <form id="formulario">
                                                    <div className="row p-t-20">
                                                        <InputMarketplaceAso  />
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className=" control-label">Link </label>                                                                
                                                                <input type="text" className="form-control" placeholder="" name="link" id="updlink"   />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className=" control-label">Fecha caducidad </label>                                                                
                                                                <input type="date" className="form-control" placeholder="" name="schedule" id="updschedule" required="required" />
                                                            </div>
                                                        </div>                                                                
                                                    </div>
                                                    <div className="row p-t-20"> 
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className=" control-label">Imagen </label>
                                                                <img src="" id="imgshow" height="50" />                                                        
                                                                <input type='file' className="form-control" placeholder="" name="img" style={{ width:174, borderColor: "#FFFFFF", backgroundColor: "#FFFFFF" }} id="updimg" required="required" accept="image/png" onChange ={ (e) => validarImagen(e.target.files) } />
                                                                <label className="resultado"></label>
                                                            </div>
                                                        </div> 
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className=" control-label">Descripción Imagen</label>                                                                
                                                                <input type="text" className="form-control" max="60" name="imgDescription" id="updimgDescription" required="required"  />                                                             
                                                                <label class="msglabel">Mínimo 60 caracteres</label>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                    <div className="row p-t-20">                                                
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label className=" control-label">Descripción Campaña </label>                                                                
                                                                <textarea className="form-control" placeholder="" name="description" id="upddescription" ></textarea>                                                             
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-offset-2 col-sm-10">
                        <button type="button" className="btn btn-default btnadd" id="btncreate">Ingresar</button>
                    </div>   
                </div>
            </div>
        )
    }
}

export default advertisingmarket;