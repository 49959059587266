import React, {Component} from 'react'
class roles  extends Component {                
    render(){
        return  (
            <React.Fragment>         
                <div className="col-md-3">
                     <div className="form-group diasruta"> 
                         <label className="control-label">Métodos de Pago</label>  
                         <div className="clear"></div>       
                         <label><input type="checkbox" value="1"   className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                         <label><input type="checkbox"  value="2"  className="form-control checkmodulo " />Editar</label>    
                         <label><input type="checkbox"  value="3"  className="form-control checkmodulo" /> Eliminar</label>
                         <div className="clear separador"></div> 
                         <label className=" control-label">Tipos de Documento</label>  
                         <div className="clear"></div>       
                         <label><input type="checkbox" value="4"   className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                         <label><input type="checkbox"  value="5"  className="form-control checkmodulo" />Editar</label>    
                         <label><input type="checkbox"  value="6"  className="form-control checkmodulo" /> Eliminar</label>
                         <div className="clear separador"></div> 
                         <label className=" control-label">Tipos de Marketplace</label>  
                         <div className="clear"></div>       
                         <label><input type="checkbox" value="7"   className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                         <label><input type="checkbox"  value="8"  className="form-control checkmodulo" />Editar</label>    
                         <label><input type="checkbox"  value="9"  className="form-control checkmodulo" /> Eliminar</label>
                         <div className="clear separador"></div>         
                     </div>                                                               
                </div> 
                <div className="col-md-3">
                    <div className="form-group diasruta"> 
                        <label className=" control-label">Tipos de Envío</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="10"   className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                        <label><input type="checkbox"  value="11"  className="form-control checkmodulo " />Editar</label>    
                        <label><input type="checkbox"  value="12"  className="form-control checkmodulo" /> Eliminar</label>
                        <div className="clear separador"></div> 
                        <label className=" control-label">Tipos de Flete</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="13"   className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                        <label><input type="checkbox"  value="14"  className="form-control checkmodulo " />Editar</label>    
                        <label><input type="checkbox"  value="15"  className="form-control checkmodulo" /> Eliminar</label>
                        <div className="clear separador"></div> 
                        <label className=" control-label">Estados Conceptos</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="16"   className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                        <label><input type="checkbox"  value="17"  className="form-control checkmodulo " />Editar</label>    
                        <label><input type="checkbox"  value="18"  className="form-control checkmodulo" /> Eliminar</label>
                    </div>                                                               
                </div>                                                        
                <div className="col-md-3">
                    <div className="form-group diasruta"> 
                        <label className=" control-label">Marketplace</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="22"   className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                        <label><input type="checkbox"  value="23"  className="form-control checkmodulo " />Editar</label>    
                        <label><input type="checkbox"  value="24"  className="form-control checkmodulo" /> Eliminar</label>
                        <div className="clear separador"></div>                                                                                                                                                 
                        <label className=" control-label">Publicidad Asociativo</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="25"   className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                        <label><input type="checkbox"  value="26"  className="form-control checkmodulo " />Editar</label>    
                        <label><input type="checkbox"  value="27"  className="form-control checkmodulo" /> Eliminar</label>
                        <div className="clear separador"></div> 
                        <label className=" control-label">Fabricante Distribuidor</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="28"   className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                        <label><input type="checkbox"  value="29"  className="form-control checkmodulo " />Editar</label>    
                        <label><input type="checkbox"  value="30"  className="form-control checkmodulo" /> Eliminar</label>
                        
                    </div>                                                               
                </div> 
                <div className="col-md-3">
                    <div className="form-group diasruta">     
                        <label className=" control-label">Cedis</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="31"   className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                        <label><input type="checkbox"  value="32"  className="form-control checkmodulo " />Editar</label>    
                        <label><input type="checkbox"  value="33"  className="form-control checkmodulo" /> Eliminar</label>
                        <div className="clear separador"></div> 
                        <label className=" control-label">Usuarios</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox"  value="34"   className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                        <label><input type="checkbox"  value="35"  className="form-control checkmodulo " />Editar</label>    
                        <label><input type="checkbox"  value="36"  className="form-control checkmodulo" /> Eliminar</label>

                        <div className="clear separador"></div> 
                        <label className=" control-label">Sectores</label>  
                        <div className="clear"></div>       
                        <label><input type="checkbox" value="19"   className="form-control checkmodulo" />Crear</label>                                                                                                                                                    
                        <label><input type="checkbox"  value="20"  className="form-control checkmodulo " />Editar</label>    
                        <label><input type="checkbox"  value="21"  className="form-control checkmodulo" /> Eliminar</label>


                    </div>                                                               
                </div>                                                             
            </React.Fragment>          
        )
    }
}

export default roles